import moment from 'moment';
import { SUPPORT_EMAIL } from 'services/configService';

export const C_SCORES_DATE_FORMAT = 'YYYY-MM-DD';

export const C_CHANGE_MENU_ITEM = 'menu.item.change';

export const C_EVENTS_ID_ALL = 'all';
export const C_EVENTS_ID_SCORED = 'scored';
export const C_EVENTS_ID_UNSCORED = 'unscored';
export const C_SCORE_EVENT_DELETED = 'Score has been deleted';
export const C_SCORE_EVENT_FAILED = 'There was a problem completing the action';
export const C_SCORE_EVENT_TRIAL =
    'Your current permissions do not allow scoring';
    export const C_SCORE_EVENT_OLD =
    'Event is more than 7 days old';
export const C_SCORE_EVENT_ADD_FAILED = 'Error: Score was not added';
export const C_SCORE_EVENT_DELETE_FAILED = 'Error: Score was not deleted';
export const C_SCORE_EVENT_EDIT_FAILED = 'Error: Score was not edited';
export const C_INSIGHT_NOTE_DELETED = 'Note has been deleted';
export const C_INSIGHT_NO_SCREENSHOT_SAVE_POPUP = {
    title: 'No snapshot saved',
    text: 'To include a snapshot click Cancel and then the Camera icon.',
};
export const C_INSIGHT_SNAPSHOT_FAILED = {
    title: 'Snapshot failed',
    text:
        'Snapshot had timed-out. Please wait 1 minute and try again. Sorry for the inconvenience.',
};
export const C_INSIGHT_NOT_SAVED = {
    title: 'Leave without saving?',
    text: 'Insight has not been saved.',
};
export const C_INSIGHT_FILE_TOO_LARGE = {
    title: 'File too large',
    text:
        'Imported file size is too large. Please select a file smaller than 15 mb.',
};
export const C_NO_PERMISSIONS = `You don't have permissions to perform this action. If you think this is a mistake please contact us at ${SUPPORT_EMAIL}`; 

export const C_LOGIN_FORM_INPUT_USERNAME = 'username';
export const C_LOGIN_FORM_INPUT_PASSWORD = 'password';
export const C_SCORED_CAPTION = 'Scored';
export const C_UNSCORED_CAPTION = 'Pending';

export const C_ASK_DESCRIPTION =
    'Ask us anything about the past, present or future,\nand we’ll send you a custom-made Insight directly to your inbox';
export const C_ASK_RESPONSE =
    'Thank you for reaching out\nWe’ll be crunching the numbers in the background';
export const C_ASK_PLACEHOLDER = 'Type your question';
export const C_ASK_ERROR =
    'There was a problem sending your question\nPlease try again later';

export const C_ACITIVTY_DELETED = 'Insight has been deleted';
export const C_ACTIVITY_PUBLISHED = 'Published successfully';
export const C_ACTIVITY_UNPUBLISHED = 'Unpublished successfully';

// react-datepicker now uses date-fns as their date util, so it requires a date-fns formatter
export const DATE_FNS_DATE_DISPLAY_FORMAT = 'mmm dd, yyyy';

export const MOMENTJS_DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

export const MOMENTJS_DATE_EXPORT_INSIGHT_FORMAT = 'D MMMM YYYY';

/* ======================== Graph ========================= */
export const C_FIRST_DAY_HUMAN_SCORES_DATE = moment(
    '2016-07-01',
    C_SCORES_DATE_FORMAT
).startOf('day');
export const C_TIMELINE_MIN_DATE = moment(
    '2013-01-01',
    C_SCORES_DATE_FORMAT
).startOf('day');
export const C_TIMELINE_MIN_RANGE = Math.abs(
    moment()
        .startOf('day')
        .diff(C_TIMELINE_MIN_DATE, 'days')
);
export const C_TIMELINE_MAX_DATE = moment()
    .startOf('day')
    .add(2, 'years');
export const C_TIMELINE_FORMATTED_DATES = Array.from(
    Array(C_TIMELINE_MAX_DATE.diff(C_TIMELINE_MIN_DATE, 'days') + 1).keys()
).map(daysDiff =>
    C_TIMELINE_MIN_DATE.clone()
        .add(daysDiff, 'days')
        .format(C_SCORES_DATE_FORMAT)
);
export const C_GRAPH = {
    Range: {
        Min: 30,
        Max: 365,
    },
    Dimensions: {
        AxisX: {
            Height: 30,
            MarginTop: 18,
        },
        AxisY: {
            CaptionMargin: 30,
        },
        Bottom: {
            Height: 46,
        },
        Timeline: {
            Height: 10,
            Padding: {
                Top: 10,
            },
        },
        Container: {
            Height: 200,
            Padding: {
                Left: 28,
                Right: 15,
            },
        },
        vToday: {
            dragRect: {
                width: 9,
            },
        },
    },
};

/* ======================== Circles ======================= */

export const C_HOST_CIRCLE_STROKE = 'white';
export const C_HOST_CIRCLE_SELECTED_STROKE = 'lightgreen';
export const C_TEXT_STROKE = 'white';
export const C_TEXT_FILL = 'white';
export const C_HOST_CIRCLE_MIN_RADI = 22;
export const C_HOST_CIRCLE_MAX_RADI = 50;
export const C_HOST_CIRCLE_MAX_RADI_RISK = 65;
export const C_SCORE_CIRCLE_MIN_RADI = 0;
export const C_SCORE_CIRCLE_MAX_RADI = 0;

/* ======================== Animations ==================== */

export const C_ANIMATION_DURATION = 200;
export const C_ANIMATION_SCORE_FADEIN = 500;
export const C_ANIMATION_TIMELINE_DURATION = 1000;

/* ======================== Map =========================== */

export const C_MAP_WIDTH = 940;
export const C_MAP_HEIGHT = 600;

/* ======================== Scoring ====================== */

export const C_SCORE_SLIDER_RADIUS = 12;

/* ================= Event Emitter keys ==============*/
export const GQChartPanning = 'Events::GQChart::Panning';

/* ================= Activity Types ==============*/
export const C_ACTIVITY_TYPE_BOOKMARK = 0;
export const C_ACTIVITY_TYPE_HISTORY = 1;

export const POPUP_ELEMENT_MARGIN = 5;

/* ============== Settings ==============*/

export enum SETTINGS_TABS {
    CustomWeights = 'cw',
    CountryGroups = 'cg',
}

export interface IGQSettingItem {
    id: SETTINGS_TABS;
    label: string;
}
export const SettingsItems: IGQSettingItem[] = [
    {
        id: SETTINGS_TABS.CustomWeights,
        label: 'Custom Weights',
    },
    {
        id: SETTINGS_TABS.CountryGroups,
        label: 'Country Groups',
    },
];

export const CHART_TOOLBAR_HEIGHT = 63;

export enum E_TIME_PRESET_KEYS {
    ONE_DAY = '1D',
    ONE_WEEK = '1W',
    ONE_MONTH = '1M',
    THREE_MONTHS = '3M',
    SIX_MONTHS = '6M',
    ONE_YEAR = '1Y',
    TWO_YEAR = '2Y',
    THREE_YEAR = '3Y',
}

export const TIME_WINDOW_PRESETS: {
    [key: string]: { val: number; type: string };
} = {
    // Notice - the values here were once defined as "1 month, 3 months, 6 months, 1 year",
    //  but its changed to be all in "days" in order to be compatible with the backend defined presets.
    // In MomentJS when you define, for example "3 months", and you add them to a current date,
    // the time span can be 92 days (if 2 out of those 3 months had 31 days),
    // but our backend always expect it to be 90 days.
    [E_TIME_PRESET_KEYS.ONE_DAY]: { val: 1, type: 'days' },
    [E_TIME_PRESET_KEYS.ONE_WEEK]: { val: 7, type: 'days' },
    [E_TIME_PRESET_KEYS.ONE_MONTH]: { val: 30, type: 'days' },
    [E_TIME_PRESET_KEYS.THREE_MONTHS]: { val: 90, type: 'days' },
    [E_TIME_PRESET_KEYS.SIX_MONTHS]: { val: 180, type: 'days' },
    [E_TIME_PRESET_KEYS.ONE_YEAR]: { val: 365, type: 'days' },
    [E_TIME_PRESET_KEYS.TWO_YEAR]: { val: 730, type: 'days' },
    [E_TIME_PRESET_KEYS.THREE_YEAR]: { val: 1095, type: 'days' },
};

export const HEATMAP_PRESETS: E_TIME_PRESET_KEYS[] = [
    E_TIME_PRESET_KEYS.ONE_MONTH,
    E_TIME_PRESET_KEYS.THREE_MONTHS,
    E_TIME_PRESET_KEYS.SIX_MONTHS,
    E_TIME_PRESET_KEYS.ONE_YEAR,
    E_TIME_PRESET_KEYS.TWO_YEAR,
    E_TIME_PRESET_KEYS.THREE_YEAR,
];

export const DATE_PICKER_DEFAULT_PRESETS: E_TIME_PRESET_KEYS[] = [
    E_TIME_PRESET_KEYS.ONE_WEEK,
    E_TIME_PRESET_KEYS.ONE_MONTH,
    E_TIME_PRESET_KEYS.THREE_MONTHS,
    E_TIME_PRESET_KEYS.SIX_MONTHS,
    E_TIME_PRESET_KEYS.ONE_YEAR,
];
