import * as React from 'react';
import StorageService from '../services/StorageService';
import { API_CACHE_KEYS } from '../services/lib/APIServiceInterfaces';
import appStore from '../AppStore';
import { RouteComponentProps } from 'react-router';
import { useEffect } from 'react';
import GQLoader from './GQLoader/GQLoader';

type Props = RouteComponentProps<{ token: string }>;

const Impersonate = React.memo((props: Props) => {
    useEffect(() => {
        const token = props.match.params.token;
        StorageService.set(API_CACHE_KEYS.TOKEN, token);
        StorageService.set(API_CACHE_KEYS.IMPERSONATE, true);
        appStore.setIsLoggedIn(true);
        appStore.setImpersonate(true);
        window.location.href = '/';
    }, [props.match.params]);

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
            <GQLoader />
            Loading impersonated user
        </div>
    );
});

export default Impersonate;
