import { IRootStoreExporter, VIEW } from './interfaces';
import { observable, action, computed, extendObservable } from 'mobx';
import { API_CACHE_KEYS } from './services/lib/APIServiceInterfaces';
import StorageService from './services/StorageService';

interface IAppState {
    isDebugMode: boolean;
    isCountryPageOn: boolean;
}

export class AppStore implements IRootStoreExporter {
    @observable public readonly isCountryPageOn: boolean = false;
    @observable public currentDisplayedView: VIEW = VIEW.RISK_VIEW;
    @observable public freezeRender = false;
    @observable private isDebugMode: boolean;
    @observable private firstLoad: boolean;
    @observable private agreedTerms = true;
    @observable private loggedIn =
        StorageService.get(API_CACHE_KEYS.TOKEN) !== null;
    @observable public impersonate =
        StorageService.get(API_CACHE_KEYS.IMPERSONATE) !== null;


    private lastGroupId: string;
    constructor() {
        if (typeof window !== 'undefined') {
            // Worker has no window
            const url = new URLSearchParams(window.location.search);
            const authTokenFromUrl = url.get('token');
            if (authTokenFromUrl) {
                StorageService.set(API_CACHE_KEYS.TOKEN, authTokenFromUrl);
                this.loggedIn =
                    StorageService.get(API_CACHE_KEYS.TOKEN) !== null;
                window.location.href = '/';
            }
        }
        this.toInitialState();
    }
    @action public setState<k extends keyof IAppState>(
        state: Pick<IAppState, k>
    ) {
        extendObservable(this, state);
    }

    @action public setCurrentDisplayView(view: VIEW) {
        this.currentDisplayedView = view;
    }

    public exportKeys(): any {
        return [];
    }
    public setLastGroup(id: string) {
        this.lastGroupId = id;
    }

    public get LastGroupID() {
        return this.lastGroupId;
    }

    @computed public get isLoggedIn() {
        return this.loggedIn && this.agreedTerms;
    }
    @computed public get isFirstLoad() {
        return this.firstLoad;
    }
    @computed public get isAgreeTerms() {
        return this.agreedTerms;
    }

    @action public setImpersonate(value: boolean) {
        this.impersonate = value;
    }

    @action public setIsLoggedIn(value: boolean) {
        this.loggedIn = value;
    }
    @action public setAgreedTerms(value: boolean) {
        this.agreedTerms = value;
    }

    @action public setfirstLoad(value: boolean) {
        this.firstLoad = value;
    }
    @action public toInitialState() {
        this.setState({
            isDebugMode: false,
        });
    }

    @action public setDebugMode(v: boolean) {
        this.isDebugMode = v;
    }

    @computed get debugMode() {
        return this.isDebugMode;
    }

    @action public setFreeze(v: boolean) {
        this.freezeRender = v;
    }
}

const appStore = new AppStore();
export default appStore;
