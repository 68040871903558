import CreateNewPassportPage from 'components/authPages/CreateNewPassportPage';
import ForgotPasswordPage from 'components/authPages/ForgotPasswordPage';
import Login from 'components/authPages/LoginPage';
import ResetPasswordPage from 'components/authPages/ResetPasswordPage';
import WelcomePage from 'components/authPages/WelcomePage';
import Insights from 'components/GQActivityPage/activityPage/Insights';
import GQNavbar from 'components/GQNavbar/GQNavbar';
import GQSettings from 'components/GQSettings/GQSettings';
import ContingentSelectCountry from 'components/Homepage/ContingentSelectCountry';
import CountryGraphSelectCountry from 'components/Homepage/CountryGraphSelectCountry';
import { RiskViewPage } from 'components/Homepage/RiskView';
import { SortByRiskPage } from 'components/Homepage/SortByRisk';
import ScoreEventProvider from 'components/scoringPage/ScoreEventProvider';
import Terms from 'components/TermsOfUse';
import { VIEW } from 'interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import PageContainer from 'PageContainer';
import InsightTemplateHtmlDaily from 'components/GQTemplate/InsightTemplate/InsightTemplateHtml/InsightTemplateHtmlDaily';
import InsightTemplatePdf from 'components/GQTemplate/InsightTemplate/InsightTemplatePdf/InsightTemplatePdf';
import InsightTemplateHtmlWeekly from 'components/GQTemplate/InsightTemplate/InsightTemplateHtml/InsightTemplateHtmlWeekly';
import SignalsTemplateDaily from 'components/GQTemplate/SignalsTemplate/SignalsTemplateDaily';
import SignalsTemplateWeekly from 'components/GQTemplate/SignalsTemplate/SignalsTemplateWeekly';
import SignalsTemplateMonthly from 'components/GQTemplate/SignalsTemplate/SignalsTemplateMonthly';
import Activate from './components/Activate';
import GQTemplate from 'components/GQTemplate/GQTemplate';
import rootStore, { initRootStore } from './RootStore';
import useSettings from 'hooks/useSettings';
import CustomWeightStore from 'stores/CustomWeightStore';
import { get, isEmpty } from 'lodash/fp';
import GQLoader from 'components/GQLoader/GQLoader';
import usePreload from 'hooks/usePreload';
import Logo from 'assets/general/Geoquant_Logo_Digital_White.svg';
import { initFullStory } from 'utils/FullStoryUtils';
import { RouteComponentProps } from 'react-router';
import CreateInsight from 'components/GQActivityPage/Insights/CreateInsight';
import { SortByDeltaPage } from 'components/Homepage/SortByDelta';
import { WorldGraphPage } from 'components/Homepage/WorldGraph';
import { HeatmapPage } from 'components/Heatmap/HeatmapProvider';
import { CountryGraphPage } from 'components/Homepage/CountryGraph';
import { ContingentMapPage } from 'components/Homepage/ContingentMap';
import { ContingentGraphPage } from 'components/Homepage/ContingentGraph';
import { Prompt } from 'components/Homepage/Prompt';
import APIService from 'services/APIService';
import { SUPPORT_EMAIL, getApiURL } from 'services/configService';
import {E_EVENTS_WORKER_IPC_CODES, } from 'components/eventsFeed/EventsWorkerIPC';
import Impersonate from 'components/Impersonate';
import Unsub from 'components/Unsub';

const Router = React.memo(({ loggedIn }: { loggedIn: boolean }) => {
    return loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />;
});

const LoggedInRoutes = React.memo(() => {
    return (
        <Switch>
            <Route exact={false} path={`/export/`} component={ExportRoutes} />
            <Route
                exact={true}
                path={`/${VIEW.EXPORT_COMPONENT}/:id?`}
                component={ExportComponentRoute}
            />
            <Route path="/unsub/:type/:uid" component={Unsub} />
            <Route component={RoutesWithPageContainer} />
        </Switch>
    );
});

const useInit = () => {
    const {
        settings,
        loading: loadingSettings,
    } = useSettings();
    const { didPreload, error: preloadError, preloadData } = usePreload();
    const [didInitStores, setDidInitStores] = useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(settings) || !didPreload || isEmpty(preloadData)) {
            return;
        }

        initFullStory(settings.username);

        initRootStore();

        CustomWeightStore.updateActivePreset(
            settings.activeCustomWeightsPreset
        );

        rootStore.risksStore.setDisabledRisks(
            get('permissions.dimensionResoure', settings),
            get('permissions.clientFacingIndicatorsResource', settings)
        );
        rootStore.UserStore.setUscorePermission(
            get('permissions.uscoreResource', settings)
        );
        rootStore.UserStore.setUsername(get('username', settings));
        rootStore.UserStore.setOrganization(get('organization', settings));
        rootStore.UserStore.setGeoquantFlagsPermission(
            get('permissions.geoquantResource', settings)
        );
        rootStore.UserStore.setAllPermissions(
            get('permissions', settings)
        );
        rootStore.chartStore.updateStateByScoresDelta(
            get('organization.scores_delta_offset_start', settings),
            get('organization.scores_delta_window_size', settings)
        );

        rootStore.countryStore.updateCountries(preloadData.countries);
        rootStore.risksStore.updateDimensions(preloadData.dimensions);
        rootStore.risksStore.updateAllDimensions(preloadData.dimensions.allDimensions);
        rootStore.risksStore.updateClientFacingIndicators(
            preloadData.clientFacingIndicators
        );
        rootStore.risksStore.updateContingentRisks(preloadData.contingentRisks);
        rootStore.risksStore.initRisksByType();
        rootStore.settingsStore.setActiveGroupId(
            preloadData.activeGroups.active_group
        );
        rootStore.settingsStore.setActiveGroups(
            preloadData.activeGroups.groups
        );

        rootStore.eventsFeedStore.eventsHandlerWorker.postMessage({
            code: E_EVENTS_WORKER_IPC_CODES.INIT_DATA,
            baseURL: getApiURL(),
            token: APIService.__getToken(),
            dimensionsTree: get('dimensions.fullTree', preloadData),
        });

        setDidInitStores(true);
    }, [settings, didPreload, preloadData]);

    const location = useLocation();
    const prevLocation = useRef(location);
    useEffect(() => {
        if (get('pathname', prevLocation) === get('pathname', location)) {
            return;
        }
        rootStore.routingStore.trackViewChange(prevLocation.current, location);
        prevLocation.current = location;
    }, [location]);

    return {
        didPreload,
        didInitStores,
        preloadError,
        loadingSettings,
        ready:
            didPreload &&
            !loadingSettings &&
            didInitStores &&
            !preloadError
    };
};

const RoutesWithPageContainer = React.memo(() => {
    const {
        didPreload,
        didInitStores,
        preloadError,
        loadingSettings,
    } = useInit();

    if (preloadError) {
        return (
            <PageContainer>
                <div className="preload-error">
                    <div className="preload-error-logo">
                        <img src={Logo} alt="" />
                    </div>
                    <div>A network error has occured.</div>
                    <div>Please try again in a few min.</div>
                    <div>
                        If the error persists, please contact us at{' '}
                        {SUPPORT_EMAIL}
                    </div>
                </div>
            </PageContainer>
        );
    }

    if (loadingSettings || !didPreload || !didInitStores) {
        return (
            <PageContainer>
                <GQLoader
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </PageContainer>
        );
    }
    return (
        <PageContainer didPreload>
            <GQNavbar />
            <div className="content">
                <Switch>
                    <Route
                        exact={true}
                        path={`/${VIEW.HEATMAP}`}
                        component={HeatmapPage}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.SCORE_EVENT}/:id`}
                        component={ScoreEventProvider}
                    />
                    <Route
                        exact={true}
                        component={RiskViewPage}
                        path={`/${VIEW.RISK_VIEW}`}
                    />
                    <Route
                        exact={true}
                        component={Prompt}
                        path={`/${VIEW.PROMPT}`}
                    />
                    <Route
                        exact={true}
                        component={SortByRiskPage}
                        path={`/${VIEW.SORT_BY_RISK}`}
                    />
                    <Route
                        exact={true}
                        component={SortByDeltaPage}
                        path={`/${VIEW.SORT_BT_DELTA}`}
                    />
                    <Route
                        exact={true}
                        component={WorldGraphPage}
                        path={`/${VIEW.WORLD_GRAPH}`}
                    />
                    <Route
                        exact={true}
                        component={CountryGraphPage}
                        path={`/${VIEW.COUNTRY_GRAPH}`}
                    />
                    <Route
                        exact={true}
                        component={ContingentMapPage}
                        path={`/${VIEW.CONTINGENT}`}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.COUNTRY_GRAPH_SELECT_COUNTRY}`}
                        component={CountryGraphSelectCountry}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.CONTINGENT_SELECT_COUNTRY}`}
                        component={ContingentSelectCountry}
                    />
                    <Route
                        exact={true}
                        component={ContingentGraphPage}
                        path={`/${VIEW.CONTINGENT_GRAPH}`}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.INSIGHTS}/:id?`}
                        component={Insights}
                    />
                    <Route
                        exact={false}
                        path={`/${VIEW.CREATE_INSIGHT}/:insightsView/:id?`}
                        component={CreateInsight}
                    />
                    <Route
                        exact={true}
                        path={`/${VIEW.SETTINGS}/:tab?`}
                        component={GQSettings}
                    />
                    <Route path="/impersonate/:token" component={Impersonate} />
                    <Route
                        render={() => {
                            return <Redirect to={`/${VIEW.RISK_VIEW}`} />;
                        }}
                    />
                </Switch>
            </div>
        </PageContainer>
    );
});

const ExportComponentRoute = React.memo(
    (props: RouteComponentProps<{ id: string }>) => {
        const { ready } = useInit();
        if (!ready) {
            return null;
        }
        return <GQTemplate id={props.match.params.id} />;
    }
);

const ExportRoutes = React.memo(() => {
    return (
        <Switch>
            <Route
                exact={true}
                path={`/export/${VIEW.SIGNALS_DAILY}`}
                component={SignalsTemplateDaily}
            />
            <Route
                exact={true}
                path={`/export/${VIEW.SIGNALS_WEEKLY}`}
                component={SignalsTemplateWeekly}
            />
            <Route
                exact={true}
                path={`/export/${VIEW.SIGNALS_MONTHLY}`}
                component={SignalsTemplateMonthly}
            />
            <Route
                exact={true}
                path={`/export/${VIEW.INSIGHT_HTML_DAILY}/:id?`}
                component={InsightTemplateHtmlDaily}
            />
            <Route
                exact={true}
                path={`/export/${VIEW.INSIGHT_HTML_WEEKLY}/:id?`}
                component={InsightTemplateHtmlWeekly}
            />
            <Route
                exact={true}
                path={`/export/${VIEW.INSIGHT_PDF}/:id?`}
                component={InsightTemplatePdf}
            />
        </Switch>
    );
});

const LoggedOutRoutes = React.memo(() => {
    return (
        <Switch>
            <Route path="/welcome" component={WelcomePage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/login" component={Login} />
            <Route
                path="/create-new-password"
                component={CreateNewPassportPage}
            />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route path="/terms" component={Terms} />
            <Route path="/activate/:token" component={Activate} />
            <Route path="/impersonate/:token" component={Impersonate} />
            <Route path="/unsub/:type/:uid" component={Unsub} />
            <Route component={Login} />
        </Switch>
    );
});

export default Router;
