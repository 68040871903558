import React from 'react';
import { IActiveGroup } from '../../interfaces';
import { map } from 'lodash/fp';

type Props = {
    groups?: IActiveGroup[];
    selectedGroup?: string;
    onGroupClick: (id: string, scroll: number) => void;
    activeGroupScroll: number;
}

type State = {
    scrollLeft: number;
}

class GroupSection extends React.Component<Props, State> {
    public containerRef: HTMLDivElement = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            scrollLeft: this.props.activeGroupScroll,
        };
    }

    public componentDidMount() {
        this.setState({ scrollLeft: this.props.activeGroupScroll });
        this.containerRef.scrollLeft = this.props.activeGroupScroll;
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.scrollLeft !== prevState.scrollLeft) {
            this.containerRef.scrollLeft = this.state.scrollLeft;
        }
    }

    private handleMoveToRight = () => {
        this.setState({
            scrollLeft:
                this.state.scrollLeft + this.containerRef.clientWidth / 2,
        });
    };

    private handleMoveToleft = () => {
        this.setState({
            scrollLeft:
                this.state.scrollLeft - this.containerRef.clientWidth / 2,
        });
    };

    private isOverFlow = () => {
        return this.containerRef.clientWidth < this.containerRef.scrollWidth;
    };

    private onGroupClick = (group: IActiveGroup): any => {
        this.props.onGroupClick(group.id, this.state.scrollLeft);
    };

    public render() {
        const { groups, selectedGroup } = this.props;
        return (
            <div
                className="groups-section-dropdown"
                ref={el => {
                    this.containerRef = el;
                }}>
                {map(
                    group => (
                        <div
                            key={group.id}
                            className={
                                group.id === selectedGroup ? 'selected' : ''
                            }
                            onClick={() => this.onGroupClick(group)}>
                            {' '}
                            {group.name}{' '}
                        </div>
                    ),
                    groups
                )}
                {this.containerRef &&
                    this.isOverFlow() &&
                    this.state.scrollLeft <
                        this.containerRef.scrollWidth -
                            this.containerRef.clientWidth && (
                        <span
                            onClick={this.handleMoveToRight}
                            className="arrow-right">
                            <span className="arrow" />
                        </span>
                    )}
                {this.containerRef && this.state.scrollLeft > 0 && (
                    <span
                        onClick={this.handleMoveToleft}
                        className="arrow-left">
                        <span className="arrow" />
                    </span>
                )}
            </div>
        );
    }
}

export default GroupSection as React.ComponentClass<Props, State>;
