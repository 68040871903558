import { C_TIMELINE_MIN_DATE, C_TIMELINE_MAX_DATE, C_FIRST_DAY_HUMAN_SCORES_DATE } from '../../constants';
import { Moment } from 'moment';
const offsets: { [dateStr: string]: number } = {};
const offsetsContingent: { [dateStr: string]: number } = {};
const offsetsMoment: { [dateStr: string]: Moment } = {};
let index = 0;
for (
    const d = C_TIMELINE_MIN_DATE.clone();
    d.isSameOrBefore(C_TIMELINE_MAX_DATE, 'days');
    d.add(1, 'days'), index++
) {
    offsets[d.format('YYYY-MM-DD')] = index;
    offsetsMoment[d.format('YYYY-MM-DD')] = d.clone();
}
index = 0;
for (
    const d = C_FIRST_DAY_HUMAN_SCORES_DATE.clone();
    // todo: make sure this is CR max date
    d.isSameOrBefore(C_TIMELINE_MAX_DATE, 'days');
    d.add(1, 'days'), index++
) {
    offsetsContingent[d.format('YYYY-MM-DD')] = index;
}

const keysArray = Object.keys(offsets);
function proxyGetOffset(target: number[], key: string) {
    return target[offsets[key]];
}
function proxyGetOffsetContingent(target: number[], key: string){
    return target[offsetsContingent[key]];
}

function proxyHas(target: number[], key: string): boolean {
    return key in offsets;
}

function keys() {
    return keysArray;
}

function momentFor(key: string) {
    return offsetsMoment[key];
}

function iterator(
    scores: number[],
    cb: (dateStr: string, moment: Moment, score: number, index?: number) => void
) {
    keysArray.forEach((k, i) => {
        cb(k, offsetsMoment[k], scores[i], i);
    });
}

const handler = {
    has: proxyHas,
    get: proxyGetOffset,
    getContingent: proxyGetOffsetContingent,
    keys,
    momentFor,
    iterator,
};

export default handler;
export { offsets as dateStrToOffset };
