import { IBaseEvent } from "interfaces";
import moment from "moment";
import UserStore from "stores/UserStore";

export const getEventCountries = (
    event: IBaseEvent,
    includeMentionedCountries: boolean
) => {
    return includeMentionedCountries ? event.countries : event.focus_countries;
};

export const isEventNew = (event: IBaseEvent) => {
    return moment().diff(event.time, 'days') <= 7;
};

export const canDeleteScore = (event: IBaseEvent, userStore: typeof UserStore) => {
    const { can_delete, can_edit_old } = userStore.uscoresPermissions;
    return can_delete && event && (isEventNew(event) || can_edit_old);
};

export const canScore = (event: IBaseEvent, userStore: typeof UserStore) => {
    const { can_score, can_edit_old } = userStore.uscoresPermissions;
    return can_score && event && (isEventNew(event) || can_edit_old);
};

export const canEditScore = (event: IBaseEvent, userStore: typeof UserStore) => {
    const { can_edit, can_edit_old } = userStore.uscoresPermissions;
    return can_edit && event && (isEventNew(event) || can_edit_old);
};
