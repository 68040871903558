import { map, flow, orderBy } from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { TBody } from '../InsightTemplate/InsightTemplateElements';
import {
    SignalsTemplateTable,
    TR,
    Header as SignalsHeader,
    SignalsPage,
    TD,
    VSpace,
    Text,
    useSignalsData,
    getCompositeRiskCell,
    getPulseCell,
    getHighLowCell,
    getRiskBandCell,
    getCountryCell,
    getRiskCell,
    getDateCell,
    Footer,
    Table as HtmlTable,
    LogoImage,
} from './SignalsTemplateComponents';

const COLS = [
    { text: 'Country', width: '16%' },
    { text: 'Risk', width: '18%' },
    { text: 'Date', width: '11%' },
    { text: 'Pulse (Value)', width: '12%' },
    { text: 'Composite (Change)', width: '15%', fontSize: '8px' },
    { text: 'New High / Low', width: '13%' },
    { text: 'Risk Band', width: '15%' },
];

const Table = React.memo(({ data }: { data: any }) => {
    const rows = flow(
        map((row: any) => ({ ...row, dateEpoch: moment(row.date).valueOf() })),
        orderBy(['country', 'dateEpoch', 'risk'], ['asc', 'asc', 'asc']),
        map((row: any) => {
            return [
                getCountryCell(row),
                getRiskCell(row, { fontSize: '8px' }),
                getDateCell(row),
                getPulseCell(row),
                getCompositeRiskCell(row),
                getHighLowCell(row),
                getRiskBandCell(row),
            ];
        })
    )(data);

    return (
        <TR>
            <TD>
                <SignalsTemplateTable columns={COLS} rows={rows} />
            </TD>
        </TR>
    );
});

const Header = React.memo(
    ({ reportDate, timeWindow }: { reportDate: Date; timeWindow: string }) => {
        return (
            <React.Fragment>
                <TR>
                    <TD>
                        <SignalsHeader>MONTHLY SIGNALS</SignalsHeader>
                    </TD>
                </TR>
                <TR>
                    <TD align="center">
                        <Text bold>
                            Signals Observed on{' '}
                            {moment(reportDate).format('DD MMM YYYY')}, over{' '}
                            {timeWindow} period
                        </Text>
                    </TD>
                </TR>
                <VSpace />
                <TR>
                    <TD style={{ padding: '0 12px' }}>
                        <HtmlTable>
                            <TBody>
                                <TR>
                                    <TD
                                        style={{
                                            paddingBottom: 8,
                                        }}>
                                        <LogoImage />
                                    </TD>
                                    <TD
                                        align="left"
                                        style={{ paddingLeft: 16 }}>
                                        <Text bold>GeoQuant Signals</Text>{' '}
                                        <Text>
                                            alert clients to significant
                                            movements in our daily risk
                                            indicators
                                        </Text>
                                    </TD>
                                </TR>
                            </TBody>
                        </HtmlTable>
                    </TD>
                </TR>
            </React.Fragment>
        );
    }
);

const SignalsTemplateMonthly = React.memo(() => {
    const { signals, reportDate, timeWindow, uid } = useSignalsData();

    return (
        <SignalsPage>
            <Header reportDate={reportDate} timeWindow={timeWindow} />
            <VSpace />
            <Table data={signals} />
            <VSpace />
            <Footer uid={uid} />
        </SignalsPage>
    );
});

export default SignalsTemplateMonthly;
