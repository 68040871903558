import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GQButton } from './GQButton';
import APIService from 'services/APIService';
import GQLoader from './GQLoader/GQLoader';
import { SUPPORT_EMAIL } from 'services/configService';

const Unsub = React.memo(() => {
    const { uid } = useParams();
    const [didUnsub, setDidUnsub] = useState<boolean>(false);
    const [didAbort, setDidAbort] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoadingUnsub, setIsLoadingUnsub] = useState(false);
    const onUnsubClick = useCallback(() => {
        setIsLoadingUnsub(true);
        APIService.unsub(uid)
            .then(() => {
                setDidUnsub(true);
                setIsLoadingUnsub(false);
            })
            .catch(e => {
                console.error('unsub error', e);
                setIsError(true);
                setIsLoadingUnsub(false);
            });
    }, [uid]);
    const onAbortClick = useCallback(() => {
        setDidAbort(true);
    }, []);
    return (
        <div className="unsub-container">
            <div className="unsub">
                {didAbort ? (
                    <div className="unsub-title">
                        No worries, you'll keep getting the emails
                    </div>
                ) : (
                    <>
                        {!isError && !didUnsub && (
                            <>
                                <div className="unsub-title">
                                    Are you sure you want to unsubscribe from
                                    GeoQuant's Signals emails?
                                </div>
                                <div className="unsub-buttons">
                                    <GQButton
                                        caption="Yes, unsubscribe me"
                                        onClick={onUnsubClick}
                                        style={{
                                            marginBottom: '1rem',
                                            paddingBlock: '1rem',
                                        }}
                                    />
                                    <GQButton
                                        caption="No. I want to keep getting the emails"
                                        onClick={onAbortClick}
                                        style={{ paddingBlock: '1rem' }}
                                    />
                                </div>
                            </>
                        )}
                        {isLoadingUnsub && <GQLoader />}
                        {/* fix loading position in UI */}
                        {isError && (
                            <div className="unsub-error">
                                <div className="unsub-title">
                                    Error unsubscribing.{' '}
                                </div>
                                <div>
                                    {' '}
                                    For any help contact:{' '}
                                    <a href={`mailto:${SUPPORT_EMAIL}`}>
                                        {SUPPORT_EMAIL}
                                    </a>{' '}
                                </div>
                            </div>
                        )}
                        {didUnsub && (
                            <div className="unsub-success">
                                You've been successfully unsubscribed from the
                                Signals email
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});

export default Unsub;
