import React from 'react';
import APIService, {
    IEvent,
    IEventUScore,
    IActiveCountry,
    DeleteEventScoresResponse,
} from '../../services/APIService';
import ScoreArticleContainer from './GQScroeArticleContainer';
import PastScoreContainer from './GQPastScores/GQPastScoreContainer';
import { GQToastContainer } from '../GQToast/GQToastContainer';
import {
    C_SCORE_EVENT_DELETED,
    C_SCORE_EVENT_TRIAL,
    C_SCORE_EVENT_DELETE_FAILED,
    C_SCORE_EVENT_EDIT_FAILED,
    C_SCORE_EVENT_ADD_FAILED,
    C_SCORE_EVENT_OLD,
} from '../../constants';
import { E_TOAST_TYPE } from '../GQToast/GQToast';
import { GQButton } from '../GQButton';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from '../../RootStore';
import GQLoader from '../GQLoader/GQLoader';
import moment from 'moment';
import { get, map, filter } from 'lodash/fp';
import { EventScoreType } from 'components/eventsFeed/EventsFeedInterfaces';
import { isEventNew } from 'components/eventsFeed/eventUtils';

export interface IGQScoreArticleProps {
    event: IEvent;
    canUserScore: boolean;
    canUserEditScore: boolean;
    canUserDeleteScore: boolean;
    countriesList: IActiveCountry[];
    organizationId?: string;
}

export interface IGQPastScoreContainerState {
    editEventScore?: IEventUScore;
    editMode: boolean;
    event: IEvent;
    showLoader: boolean;
    uscores: IEventUScore[];
}

const SCORE_EVENT_TIME_FORMAT = 'YYYY-MM-DD';
const emptyEventScoreTemplate: IEventUScore = {
    country_id: 0,
    dimension_id: 0,
    impact: 0,
    duration: 90,
    score_id: null,
    organization_id: null,
};

@inject(({ UserStore, countryStore }: IMobxRootState) => {
    return {
        organizationId: UserStore.organization.id,
        countriesList: get(['countries', 'licensedCountriesArr'], countryStore),
    };
})
@observer
export default class ScoreArticle extends React.Component<
    IGQScoreArticleProps,
    IGQPastScoreContainerState
> {
    private toaster: GQToastContainer;
    constructor(props: IGQScoreArticleProps) {
        super(props);
        this.state = {
            editMode: false,
            event: this.props.event,
            showLoader: false,
            uscores: get('event.uscores', this.props) || [],
        };
    }

    public componentDidMount(): void {
        emptyEventScoreTemplate.organization_id = this.props.organizationId;
    }

    public componentDidUpdate(prevProps: Readonly<IGQScoreArticleProps>): void {
        emptyEventScoreTemplate.organization_id = this.props.organizationId;
        if (
            (prevProps.event &&
                this.props.event &&
                prevProps.event.id !== this.props.event.id) ||
            this.state.event === null
        ) {
            this.setState({
                editEventScore: emptyEventScoreTemplate,
                editMode: false,
                event: this.props.event,
                uscores: get('event.uscores', this.props) || [],
            });
        }
    }

    public render() {
        return (
            <div>
                <GQToastContainer ref={el => (this.toaster = el)} />
                {this.state.showLoader ? (
                    <GQLoader />
                ) : (
                    <div>
                        {this.state.event && (
                            <div
                                className="past-score-container"
                                style={{ width: '263px' }}>
                                <div className="past-score-header">
                                    {' '}
                                    Score article
                                </div>
                                <div
                                    style={{
                                        height: 'auto',
                                        position: 'relative',
                                        zIndex: 100,
                                    }}>
                                    {' '}
                                    <ScoreArticleContainer
                                        event={this.state.event}
                                        score={this.state.editEventScore}
                                        editMode={this.state.editMode}
                                        onAddedScore={this.onNewScoreAdded}
                                        onEdittedScore={this.onEdittedScore}
                                        countriesList={this.props.countriesList}
                                    />
                                </div>
                                <PastScoreContainer
                                    canUserDelete={
                                        this.props.canUserDeleteScore
                                    }
                                    canUserEdit={this.props.canUserEditScore}
                                    pastScores={this.state.uscores}
                                    onEdit={this.onEdit}
                                    onDelete={this.onDelete}
                                    onDeleteAllScores={this.deleteAllScores}
                                    editMode={this.state.editMode}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    private onEdit = (score: IEventUScore) => {
        this.setState({
            editEventScore: score,
            editMode: !this.state.editMode,
        });
    };

    private formatEventTime = (): string => {
        return moment(this.props.event.time).format(SCORE_EVENT_TIME_FORMAT);
    };

    private onDelete = async (score: IEventUScore) => {
        const form = {
            ...score,
            event_id: this.props.event.id,
            delete: true,
            event_time: this.formatEventTime(),
        };

        try {
            await APIService.postUscore(form);

            this.setState({
                editEventScore: emptyEventScoreTemplate,
                uscores: filter(
                    (uscore: IEventUScore) =>
                        uscore.score_id !== score.score_id,
                    this.state.uscores
                ),
            });
            this.showToaster(score);
        } catch (err) {
            this.showErrorToaster(C_SCORE_EVENT_DELETE_FAILED);
        } finally {
        }
    };

    private deleteAllScores = async (scoreType: EventScoreType) => {
        this.setState({ showLoader: true });
        try {
            const { uscores }: DeleteEventScoresResponse = await APIService.deleteEventScores(this.props.event.id, scoreType);

            this.setState({
                uscores,
                showLoader: false,
            });
        } catch (err) {
            const errMsg =
                err && err.message
                    ? err.message
                    : 'Something went wrong, but some (or all) of the scores might have been deleted. Please review the scores and check.';
            this.showErrorToaster(errMsg);
            const event = await APIService.getScoreEvent(this.props.event.id);
            this.setState({
                event,
                showLoader: false,
            });
        }
    };

    private onEdittedScore = async (score: IEventUScore) => {
        if (!this.props.canUserScore) {
            const message = !isEventNew(this.props.event) ? C_SCORE_EVENT_OLD : C_SCORE_EVENT_TRIAL;
            this.showErrorToaster(message);
            return;
        }

        const editScoreForm = {
            ...score,
            event_id: this.props.event.id,
            delete: false,
            event_time: this.formatEventTime(),
            human: true,
        };

        try {
            await APIService.postUscore(editScoreForm);

            const scoresAfterUpdate = map((currentScore: IEventUScore) => {
                return currentScore.score_id === editScoreForm.score_id
                    ? editScoreForm
                    : currentScore;
            }, this.state.uscores);

            this.setState({
                editMode: false,
                editEventScore: emptyEventScoreTemplate,
                uscores: scoresAfterUpdate,
            });
        } catch (err) {
            this.showErrorToaster(C_SCORE_EVENT_EDIT_FAILED);
        } finally {
        }
    };

    private onNewScoreAdded = async (score: IEventUScore) => {
        if (!this.props.canUserScore) {
            const message = !isEventNew(this.props.event) ? C_SCORE_EVENT_OLD : C_SCORE_EVENT_TRIAL;
            this.showErrorToaster(message);
            return;
        }

        const newScoreForm = {
            ...score,
            event_id: this.props.event.id,
            delete: false,
            event_time: this.formatEventTime(),
            organization_id: emptyEventScoreTemplate.organization_id,
            human: true,
        };

        try {
            const newScoreId = await APIService.postUscore(newScoreForm);

            const newUscore = {
                ...newScoreForm,
                score_id: newScoreId,
            };

            this.setState({
                uscores: [...this.state.uscores, newUscore],
                editEventScore: emptyEventScoreTemplate,
            });
        } catch (err) {
            this.showErrorToaster(C_SCORE_EVENT_ADD_FAILED);
        } finally {
        }
    };

    private showToaster = (score: IEventUScore) => {
        if (this.toaster) {
            const onUndo = () => {
                this.onNewScoreAdded(score);
            };
            this.toaster.createToast(C_SCORE_EVENT_DELETED, {
                type: E_TOAST_TYPE.SUCCESS,
                buttons: [
                    <GQButton
                        key="score_event_delete_toast"
                        caption="Undo"
                        onClick={onUndo}
                    />,
                ],
            });
        }
    };

    private showErrorToaster = (errorReason: string) => {
        if (this.toaster) {
            this.toaster.createToast(errorReason, {
                type: E_TOAST_TYPE.ERROR,
            });
        }
    };
}
