import { withHistory } from 'slate-history';
import React from 'react';
import {
    IActivityRecord,
    IPrediction,
    IActivitySnapshot,
    IActiveCountryMap,
} from '../../../services/lib/APIServiceInterfaces';
import GQLoader from '../../GQLoader/GQLoader';
import PopupStorage from '../../GQPopup/GQPopupStorage';
import EventsService, {
    EventSubscription,
} from '../../../services/EventsService';
import GQFlag from '../../GQFlag';
import moment from 'moment';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../../constants';
import * as _ from 'lodash';
import RisksStore from '../../../stores/RisksStore';
import { VIEW } from 'interfaces';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import InsightTextEditor, { withInlines } from '../Insights/InsightTextEditor';
import { ReactEditor, withReact } from 'slate-react';
import { withHtml } from '../Insights/SlateHTML';
import { createEditor, } from 'slate';
import GQButton from '../../GQButton/GQButton';

export const ActivityPopupKey = 'insight_view_tooltip';

type Props = {
    activeItem?: IActivityRecord | IPrediction;
    loading: boolean;
    allCountries?: IActiveCountryMap;
};

@inject(({ countryStore }: IMobxRootState) => {
    return {
        allCountries: countryStore.countries.allCountries,
    };
})
@observer
export default class ActivityView extends React.Component<Props> {
    private onClickSub: EventSubscription = null;
    private editor: ReactEditor;

    constructor(props: Props){
        super(props)
        this.editor = withInlines(
            withHtml(withHistory(withReact(createEditor())))
        );

    }
    public componentDidMount() {
        this.onClickSub = EventsService.registerOnMouseLeftClick(() => {
            PopupStorage.addData(ActivityPopupKey, null);
        });
    }
    public componentWillUnmount() {
        this.onClickSub.remove();
    }

    public render() {
        return (
            <div className="activity-view-container col filler">
                {this.renderView()}
            </div>
        );
    }

    private renderView() {
        if (this.props.loading) {
            return <GQLoader />;
        }

        if (this.isInsight(this.props.activeItem)) {
            return this.renderInsightMode(this.props.activeItem);
        }

        if (this.isPrediction(this.props.activeItem)) {
            return this.renderPrediction(this.props.activeItem);
        }
        return null;
    }

    private renderInsightMode(insight: IActivityRecord) {
        return (
            <div
                key="activityViewContent"
                className="activity-view-content selectable-text col filler">
                {insight ? (
                    <>
                        <div
                            className="activity-view-content-header row">
                            <span
                                title={
                                    insight.insightsData.name.length > 50
                                        ? insight.insightsData.name
                                        : ''
                                }
                                className="activity-view-title">
                                {insight.insightsData.name}
                            </span>
                            {insight.pdfURL && <span>
                                <GQButton
                                    icon="gqi-pdf"
                                    noBorder
                                    style={{ fontSize: 18}}
                                    tooltip="Export PDF"
                                    onClick={()=>window.open(insight.pdfURL)}
                            /></span>}
                        </div>
                        <div
                            className="row">
                            <span
                                className="activity-view-date">
                                {moment(insight.date).format('MMMM DD, YYYY')}
                            </span>
                        </div>
                        <div
                            className="activity-view-content-subheader-tags">
                            <span>
                                <i className="gqi-tags" />
                                <span>
                                    {insight.insightsData.tags.join(', ')}
                                </span>
                            </span>
                        </div>
                        <div
                            className="activity-view-content-text">
                            <InsightTextEditor
                                editor={this.editor}
                                showToolbar={false}
                                value={insight.insightsData.insight.nodes}
                                readOnly
                                getComponentById={this.getComponentById}
                            />
                        </div>
                    </>
                ) : (
                    <GQLoader
                        style={{
                            position: 'absolute',
                            left: '46%',
                            top: '50%',
                        }}
                    />
                )}
            </div>
        );
    }

    private renderPrediction(prediction: IPrediction) {
        if (prediction) {
            return (
                <div className="prediction-view-container">
                    <div className="prediction-view flex-container flex-dir-column">
                        <div className="prediction-view-header flex-container">
                            <GQFlag
                                countryId={prediction.country_id}
                                options={{ round: true }}
                                style={{ marginRight: 12 }}
                            />
                            <span>
                                {
                                    this.props.allCountries[
                                        prediction.country_id
                                    ].name
                                }
                            </span>
                        </div>
                        <div className="prediction-view-separator" />
                        <div className="prediction-view-description">
                            <p>{prediction.abbreviation}</p>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: this.linkify(
                                        prediction.description
                                    ),
                                }}
                            />
                        </div>
                        {_.map(prediction.insight_refs, (title, insightId) => {
                            return (
                                <div
                                    key={insightId}
                                    className="prediction-view-insight-links">
                                    <i className="gqi-hyperlink" />
                                    <a
                                        key={insightId}
                                        href={`#/${VIEW.INSIGHTS}/${insightId}`}
                                        target="_blank" rel="noopener noreferrer">
                                        {title}
                                    </a>
                                </div>
                            );
                        })}
                        <div className="prediction-view-separator" />
                        <div className="prediction-view-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Call Made</th>
                                        <th>Realization</th>
                                        <th>Confidence</th>
                                        <th>Consensus</th>
                                        <th>Outcome</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {moment(
                                                Date.parse(prediction.call_date)
                                            ).format(
                                                MOMENTJS_DATE_DISPLAY_FORMAT
                                            )}
                                        </td>
                                        <td>
                                            {moment(
                                                Date.parse(
                                                    prediction.realization_date
                                                )
                                            ).format(
                                                MOMENTJS_DATE_DISPLAY_FORMAT
                                            )}
                                        </td>
                                        <td>{prediction.confidence}</td>
                                        <td>{prediction.consensus}</td>
                                        <td>{prediction.outcome}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="prediction-view-separator" />
                        <div className="prediction-view-tags flex-container align-middle">
                            <i className="gqi-tags" />
                            <span>{prediction.tags}</span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className="activity-view-no-data filler" />;
        }
    }

    private getComponentById = (id: string) => {
        if (this.isInsight(this.props.activeItem)) {
            return _.find(this.props.activeItem.components, c => c.id === id);
        }
    };

    private isInsight(t: any): t is IActivityRecord {
        return t !== null && typeof t._id === 'string';
    }

    private isPrediction(t: any): t is IPrediction {
        return t !== null && typeof t.id === 'number';
    }
//@ts-ignore
    private checkDisableRestore = (snapshot: IActivitySnapshot) => {
        if (snapshot !== null) {
            return RisksStore.checkIfRiskDisabled(
                snapshot.riskType,
                snapshot.risks.values
            );
        } else {
            return false;
        }
    };
    private linkify(inputText: string) {
        const replacePattern1 = /(#\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
        const replacedText = inputText.replace(
            replacePattern1,
            '<a href="$1" target="_blank" style="color: rgb(157, 171, 255);">$1</a>'
        );

        return replacedText;
    }
}
