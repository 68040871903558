import * as _ from 'lodash';
import React from 'react';
import cx from 'classnames';

import { TweenLite } from 'gsap';
import GQFlag from '../../GQFlag';
import GQTabs from '../../GQTabs/GQTabs';
import GQLoader from '../../GQLoader/GQLoader';
import { ActivityPopupKey } from './ActivityView';
import ActivityRecordRow from './ActivityRecordRow';
import PopupStorage from '../../GQPopup/GQPopupStorage';
import { Transition } from 'react-transition-group';
import EventsService, {
    EventSubscription,
} from '../../../services/EventsService';
import {
    IPrediction,
    IBasicActivityRecord,
    IActiveDimension,
    IAPIActiveClientFacingIndicator,
} from '../../../services/lib/APIServiceInterfaces';
import { E_TIME_FILTER, E_TAB_FILTER } from './ActivityPageInterfaces';
import { GQButton } from '../../GQButton';
import moment from 'moment';
import { inject } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import { findIndex, get, size } from 'lodash/fp';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

export const ActivityTimePopupKey = 'insight_time_filter';

type Tab = { label: string, id: E_TAB_FILTER};

interface Props {
    predictions: IPrediction[];
    activities?: IBasicActivityRecord[];
    activePredictionId: number;
    activeInsightId: string;
    activeTab: E_TAB_FILTER;
    timeFilter: E_TIME_FILTER;
    canShareInsights: boolean;
    canDownloadInsightData: boolean;
    showSearch: boolean;
    searchVal: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    toggleSearch: (e: React.MouseEvent<HTMLDivElement>) => void;
    clearSearch: (e: React.MouseEvent<HTMLDivElement>) => void;
    onTabChange: (tab: E_TAB_FILTER) => void;
    onPredictionClick: (id: number) => void;
    onActivitySelect: (activity: IBasicActivityRecord) => void;
    onActivityDelete: (activity: IBasicActivityRecord) => void;
    onActivityEdit: (activity: IBasicActivityRecord) => void;
    onActivityDownload: (activity: IBasicActivityRecord) => void;
    onActivityBroadcast: (
        activity: IBasicActivityRecord,
        sendEmail: boolean
    ) => void;
    onTimeFilterChange: (filter: E_TIME_FILTER) => void;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    tabs?: Tab[];
}

interface State {
    activityListContainer: HTMLElement;
}

@inject(
    ({ risksStore, UserStore }: IMobxRootState): Partial<Props> => {
        const bmiFeedVisible = get(
            ['insightsResource', 'bmi_feed_visible'],
            UserStore.allPermissions
        );

        const tabs = [
            {
                label: 'Geoquant Insights',
                id: E_TAB_FILTER.GQ,
            },
            {
                label: 'MY Insights',
                id: E_TAB_FILTER.MY,
            },
            {
                label: 'Predictions',
                id: E_TAB_FILTER.PREDICTIONS,
            },
        ];

        if (bmiFeedVisible) {
            tabs.splice(1, 0, {
                label: 'BMI Insights',
                id: E_TAB_FILTER.BMI,
            });
        }
        return {
            dimensions: risksStore.dimensions.hashed,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
            tabs,
        };
    }
)
export default class ActivityPage extends React.Component<Props, State> {
    private timeFilters = {
        [E_TIME_FILTER.WEEK]: 'Past Week',
        [E_TIME_FILTER.MONTH]: 'Past Month',
        [E_TIME_FILTER.ALL]: 'All Time',
    };
    private onClickSub: EventSubscription = null;
    private lastSearchRef: HTMLInputElement = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            activityListContainer: null,
        };
    }

    public componentDidMount() {
        this.onClickSub = EventsService.registerOnMouseLeftClick(() => {
            PopupStorage.addData(ActivityTimePopupKey, null);
        });
    }

    public componentWillUnmount() {
        this.onClickSub.remove();
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.activeInsightId && !prevProps.activeInsightId) {
            const index = findIndex(
                item => item._id === this.props.activeInsightId,
                this.props.activities
            );
            if (index > 0) {
                this.activityListRef?.current?.scrollToItem(index, 'start');
            }
            return;
        }
        if (this.props.activePredictionId && !prevProps.activePredictionId) {
            const index = findIndex(
                item => item.id === this.props.activePredictionId,
                this.props.predictions
            );
            if (index > 0) {
                // we need to let the tab change into 'predictions' before scrolling
                setTimeout(() => {
                    this.predictionsListRef?.current?.scrollToItem(
                        index,
                        'start'
                    );
                }, 0);
            }
            return;
        }
    }

    public render() {
        return (
            <div className="gq-activity-history-content">
                {this.isLoadingState() ? (
                    <div style={{ height: '100%' }}>
                        <GQLoader
                            style={{
                                position: 'absolute',
                                top: '45%',
                                left: 'calc(50% - 35px)',
                            }}
                        />
                    </div>
                ) : (
                    <div className="grid-y gq-activity-history-table-container">
                        <div
                            className="gq-activity-history-content-header cell small-1 grid-x"
                            style={{ height: 40 }}>
                            <GQTabs
                                tabs={this.props.tabs}
                                onChange={this.onTabChange}
                                selectedTab={this.props.activeTab}
                            />
                            <div className="activity-time-filter filler">
                                <GQButton
                                    icon="gqi-search"
                                    onClick={this.props.toggleSearch}
                                    noBorder
                                    tooltip="Search"
                                    toggleButton
                                    active={this.props.showSearch}
                                />
                                <span>
                                    <span>Show:</span>
                                    <span
                                        className="activity-time-filter-dropdown"
                                        onClick={this.onTimeFilterClick}>
                                        <span>
                                            {
                                                this.timeFilters[
                                                    this.props.timeFilter
                                                ]
                                            }
                                        </span>
                                        <span>
                                            <i className="gqi-dropdown" />
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="gq-activity-search-bar-container">
                            <Transition
                                timeout={500}
                                in={this.props.showSearch}
                                onEnter={this.onSearchEnter}
                                onExit={this.onSearchExit}
                                mountOnEnter
                                unmountOnExit>
                                <div className="gq-activity-search-container flex-container align-middle">
                                    <input
                                        type="search"
                                        onChange={this.props.onSearchChange}
                                        placeholder={this.searchPlaceHolder}
                                        value={this.props.searchVal}
                                        ref={this.onSearchRef}
                                    />
                                    <GQButton
                                        icon="gqi-close"
                                        onClick={this.props.clearSearch}
                                        noBorder
                                    />
                                </div>
                            </Transition>
                        </div>
                        <div
                            ref={this.onTableRef}
                            className="cell small-10 flex-child-auto">
                            {this.renderTable()}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private onTableRef = (ref: HTMLElement) => {
        if (this.state.activityListContainer === null) {
            this.setState({
                activityListContainer: ref,
            });
        }
    };

    private onSearchRef = (ref: HTMLInputElement) => {
        if (ref !== null && this.lastSearchRef === null) {
            ref.focus();
        }
        this.lastSearchRef = ref;
    };

    private get searchPlaceHolder() {
        switch (this.props.activeTab) {
            case E_TAB_FILTER.GQ:
                return 'Search Geoquant Insights';
            case E_TAB_FILTER.MY:
                return 'Search My Insights';
            case E_TAB_FILTER.BMI:
                return 'Search BMI Insights';
            case E_TAB_FILTER.PREDICTIONS:
                return 'Search Predictions';
            default:
                throw new Error('unsupported active tab');
        }
    }

    private onSearchEnter = (node: Element) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.5, { opacity: 0, y: '-=50' });
    };

    private onSearchExit = (node: Element) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.5, { opacity: 0, y: '-=50' });
    };

    private renderTable() {
        if (this.props.activeTab === E_TAB_FILTER.PREDICTIONS) {
            return this.renderPredictionsTable();
        } else {
            return this.renderActivityTable();
        }
    }

    private predictionsListRef = React.createRef<List>();
    private renderPredictionsRow = ({ index, style, data }) => {
        const prediction = get(index, this.props.predictions);
        return (
            <div style={style}>
                <div
                    onClick={this.onPredictionClick(prediction.id)}
                    className={cx([
                        'prediction-item',
                        {
                            active: data.activePredictionId === prediction.id,
                        },
                    ])}>
                    <div className="prediction-item-country">
                        <GQFlag countryId={prediction.country_id} />
                    </div>
                    <div className="prediction-item-description">
                        <span>{prediction.abbreviation}</span>
                    </div>
                    <div className="prediction-item-call-date">
                        {moment(Date.parse(prediction.call_date)).format(
                            'MMM DD, YYYY'
                        )}
                    </div>
                    <div className="prediction-item-realization">
                        {prediction.realization_date}
                    </div>
                    <div className="prediction-item-window">
                        {prediction.window}
                    </div>
                    <div className="prediction-item-confidence">
                        {prediction.confidence}
                    </div>
                    <div className="prediction-item-consensus">
                        {prediction.consensus}
                    </div>
                    <div className="prediction-item-outcome">
                        {prediction.outcome}
                    </div>
                </div>
            </div>
        );

    }
    private renderPredictionsTable() {
        const { predictions } = this.props;
        const percent =
            (_.filter(predictions, this.filterScoredPredictions).length /
                _.filter(predictions, this.filterTotalPredictions).length) *
            100;
        return (
            <>
                <div className="activity-history-prediction-metrics">
                    <div className="activity-history-prediction-percent">
                        <span>
                            {isNaN(percent) ? '0' : percent.toFixed(1)}%
                        </span>
                    </div>
                    <div className="activity-history-prediction-label">
                        <span>Correct</span>
                    </div>
                </div>
                <div className="predictions-table-header">
                    <div className="prediction-item-country">Country</div>
                    <div className="prediction-item-description">
                        Description
                    </div>
                    <div className="prediction-item-call-date">Call Made</div>
                    <div className="prediction-item-realization">
                        Realization
                    </div>
                    <div className="prediction-item-window">Window</div>
                    <div className="prediction-item-confidence">Confidence</div>
                    <div className="prediction-item-consensus">Consensus</div>
                    <div className="prediction-item-outcome">Outcome</div>
                </div>
                <div className="predictions-table-virtualized-container">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="predictions-table-virtualized"
                                height={height}
                                itemCount={size(this.props.predictions)}
                                itemSize={28}
                                itemData={{
                                    activePredictionId: this.props
                                        .activePredictionId,
                                }}
                                ref={this.predictionsListRef}
                                width={width}>
                                {this.renderPredictionsRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </>
        );
    };

    private renderActivityRow = ({ index, style, data }) => {
        const activity = get(index, this.props.activities);
        return (
            <div style={style}>
                <ActivityRecordRow
                    key={activity._id}
                    rowHeight={28}
                    activeTab={this.props.activeTab}
                    activity={activity}
                    onSelect={this.props.onActivitySelect}
                    isActive={data.activeInsightId === activity._id}
                    onActivityDelete={this.props.onActivityDelete}
                    onActivityBroadcast={this.props.onActivityBroadcast}
                    onActivityDownload={this.props.onActivityDownload}
                    onActivityEdit={this.props.onActivityEdit}
                    dimensions={this.props.dimensions}
                    activeClientFacingIndicators={
                        this.props.activeClientFacingIndicators
                    }
                    canShare={this.props.canShareInsights}
                    canDownloadData={this.props.canDownloadInsightData}
                />
            </div>
        );
    };

    private activityListRef = React.createRef<List>();

    private renderActivityTable = () => {
        return (
            <div className="activity-history-container">
                <div className="activity-history-header">
                    <div className="activity-history-table-col">Type</div>
                    <div className="activity-history-table-col">Name</div>
                    <div className="activity-history-table-col">Published</div>
                    <div className="activity-history-table-col">
                        Countries / Dimensions
                    </div>
                    <div className="activity-history-table-col" />
                </div>
                <div className="activity-history-list">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="activity-history-virtualized"
                                height={height}
                                itemCount={size(this.props.activities)}
                                itemSize={28}
                                itemData={{
                                    activeInsightId: this.props.activeInsightId,
                                }}
                                ref={this.activityListRef}
                                width={width}>
                                {this.renderActivityRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </div>
        );
    };

    private filterScoredPredictions = (p: IPrediction) => {
        return p.outcome === 'correct' || p.outcome === 'partial';
    };

    private filterTotalPredictions = (p: IPrediction) => {
        return p.outcome !== 'pending';
    };

    private onPredictionClick = (id: number) => () => {
        this.props.onPredictionClick(id);
    };

    private isLoadingState() {
        return !(
            this.props.predictions !== null && this.props.activities !== null
        );
    }

    private onTabChange = (id: E_TAB_FILTER) => {
        this.props.onTabChange(id);
    };

    private changeTimeFilter = (filter: E_TIME_FILTER) => {
        this.props.onTimeFilterChange(filter);
    };

    private onTimeFilterClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        PopupStorage.addData(ActivityPopupKey, null);
        const el = e.currentTarget;
        PopupStorage.addData(ActivityTimePopupKey, {
            element: el,
            tooltipData: this.getTooltipDropDown(),
            orientation: 'bottom left',
        });
    };

    private getTooltipDropDown() {
        return (
            <div className="activity-time-filter-tooltip">
                {_.map(_.keys(this.timeFilters), (filter: E_TIME_FILTER) => (
                    <TimeFilterEl
                        key={filter}
                        label={this.timeFilters[filter]}
                        onClick={this.changeTimeFilter}
                        id={filter}
                    />
                ))}
            </div>
        );
    }
}

const TimeFilterEl = (props: {
    onClick: (filter: E_TIME_FILTER) => void;
    label: string;
    id: E_TIME_FILTER;
}) => {
    const onClick = () => {
        props.onClick(props.id);
    };
    return (
        <div className="activity-time-filter-tooltip-item" onClick={onClick}>
            <span>{props.label}</span>
        </div>
    );
};
