import * as d3 from 'd3';
import * as _ from 'lodash';
import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { runInAction } from 'mobx';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import {
    C_SCORES_DATE_FORMAT,
    C_TIMELINE_MAX_DATE,
    C_TIMELINE_MIN_DATE,
    MOMENTJS_DATE_DISPLAY_FORMAT,
} from '../../constants';
import {
    IChartData,
    IChartLine,
    ILineSideIndicator,
    E_DOMAIN_Y_SCALE,
    E_CHART_GROUP_IDENTIFIERS,
    IChartCircle,
    IClosestChartLine,
} from './chartInterfaces';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import annoStore from '../../stores/AnnotationsStore';
import { sortedArrayInsertIndex } from '../../utils/generalUtils';
import numeral from 'numeral';
import { pick, isEqual, get, filter, includes, isEmpty } from 'lodash/fp';
import { Cancelable, debounce, throttle } from 'lodash';

export interface IChartPadding {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}

const UPDATE_SCALES_KEYS: Array<keyof IChartProps> = [
    'minX',
    'maxX',
    'width',
    'height',
    'isDragging',
    'expanded',
    'hasToolbar',
];

const EDITOR_DATE_TOOLTIP = 'insight_editor_date';
const MIN_PIXEL_PADDING_BETWEEN_CIRCLES = 4;

const ECONIMIC_INDICATORS_DATA: E_CHART_GROUP_IDENTIFIERS[] = [
    E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE,
    E_CHART_GROUP_IDENTIFIERS.GLOBAL_INDEX,
];

const Y_AXIS_NUM_TICKS = 10;

export interface IChartProps {
    activeView: string;
    data: IChartData[];
    minX: moment.Moment;
    maxX: moment.Moment;
    width: number;
    height: number;
    emitter: any;
    virtualToday: moment.Moment;
    expanded: boolean;
    showVirtualToday: boolean;
    showCircles: boolean;
    showLines: boolean;
    isOpaque: boolean;
    isDragging: boolean;
    hasToolbar: boolean;
    onVirualTodayChange: (date: moment.Moment) => void;
    onWheel: (isUp: boolean) => void;
    onPan: (range: number) => void;
    onDragChange: (isDragging: boolean) => void;
    padding?: IChartPadding;
    style?: React.CSSProperties;
    annoMode?: boolean;
    changeDataWhenDragStop?: boolean;
}

const xaxisHeight = 20;

type ITexts = Array<{
    key: string;
    yValue: number;
    yPosition: number;
}>;

interface IChartState {
    pos: { x: number; date: moment.Moment };
    dragging: boolean;
    rel: { x: number };
    minX: moment.Moment;
    maxX: moment.Moment;
    showCirclesTimerEnded: boolean;
    insightIndicator: {
        x1: number;
        y1: number;
        y2: number;
    };
    xScale: d3.ScaleTime<number, number>;
    yScales: {
        [identifier: string]: d3.ScaleLinear<number, number>;
    };
}

const SHOW_CIRCLES_DELAY = 250;

const yAxisTickFormatter = (value: number) => {
    let format;
    switch (true) {
        case value < 0.001:
            format = '0,0.[0000]a';
            break;
        case value >= 0.001 && value < 0.01:
            format = '0,0.[000]a';
            break;
        case value >= 0.01 && value < 10:
            format = '0,0.[00]a';
            break;
        default:
            format = '0,0.[0]a';
    }
    return numeral(value).format(format);
};

export default class Chart extends React.Component<IChartProps, IChartState> {
    private padding = {
        top: 60,
        bottom: 30,
        left: 30,
        right: 24,
    };
    private xAxisRef: SVGGElement = null;
    private yAxisRef: SVGGElement = null;
    private externalDatayAxisRef: SVGGElement = null;
    private svgRef: SVGElement = null;
    private showCirclesTimer: any;
    private updateScalesThrottle: Cancelable & Function;
    private startOfDay = moment().startOf('day');
    private insightHoverDebounce: any = null;

    constructor(props: IChartProps) {
        super(props);
        this.padding = {
            ...this.padding,
            ...this.props.padding,
        };
        this.state = {
            pos: { x: 0, date: null },
            dragging: false,
            rel: null,
            minX: null,
            maxX: null,
            showCirclesTimerEnded: true,
            insightIndicator: {
                x1: null,
                y1: null,
                y2: null,
            },
            yScales: {},
            xScale: null,
        };
        this.updateScalesThrottle = throttle(this.updateScales, 33);
        this.insightHoverDebounce = debounce(this.insightHover.bind(this), 10);
    }

    public componentDidMount() {
        if (this.xAxisRef && this.yAxisRef && this.externalDatayAxisRef) {
            this.renderXAxis();
            this.renderYAxis();
            this.renderExternalDataYAxis();
        }
        this.updateScales(null);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IChartProps) {
        if (nextProps.expanded) {
            this.padding = {
                ...this.padding,
                right: 24,
                top: 60,
                ...this.props.padding,
            };
        } else {
            this.padding = {
                ...this.padding,
                right: 0,
                top: 15,
            };
        }
        if (this.props.annoMode && !nextProps.annoMode) {
            GQPopupStorage.addData(EDITOR_DATE_TOOLTIP, null);
        }
        if (
            this.props.showCircles &&
            nextProps.showCircles &&
            (!this.props.minX.isSame(nextProps.minX) ||
                !this.props.maxX.isSame(nextProps.maxX) ||
                this.props.width !== nextProps.width)
        ) {
            if (this.state.showCirclesTimerEnded) {
                this.setState(
                    { showCirclesTimerEnded: false },
                    this.resetShowCirclesTimer
                );
            } else {
                this.resetShowCirclesTimer();
            }
        }
    }

    public componentWillUnmount() {
        clearTimeout(this.showCirclesTimer);
    }

    public componentDidUpdate(prevProps: IChartProps) {
        this.updateScalesThrottle(prevProps);
    }

    private updateScales = (prevProps: IChartProps) => {
        if (this.xAxisRef && this.yAxisRef && this.externalDatayAxisRef) {
            this.renderXAxis();
            this.renderYAxis();
            this.renderExternalDataYAxis();
        }
        const previous = pick(UPDATE_SCALES_KEYS, prevProps);
        const current = pick(UPDATE_SCALES_KEYS, this.props);
        // comparing the 'data' prop is expensive,
        // so if one of the other props is not equal -
        // there is no need to compare the 'data' prop
        if (
            isEqual(current, previous) &&
            isEqual(get('data', this.props), get('data', prevProps))
        ) {
            return;
        }

        const { data } = this.props;
        const yScales: {} = {};
        data.forEach(datum => {
            const shared = this.isSharedDomain(datum);
            let scale: d3.ScaleLinear<number, number> = null;
            if (shared) {
                scale = this.getYScale(datum, 0);
                yScales[datum.identifier] = scale;
            } else {
                datum.lines.forEach((line, i) => {
                    scale = this.getYScale(datum, i);
                    yScales[`${datum.identifier}_${line.identifier}`] = scale;
                });
            }
        });
        const xScale = this.getXScale();
        this.setState({ yScales, xScale });
        runInAction(() => {
            annoStore.yScales = yScales;
            annoStore.xScale = xScale;
        });
        this.startOfDay = moment().startOf('day');
    };

    public onMouseUp = (e: MouseEvent) => {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.props.onDragChange(false);
        setTimeout(() => {
            this.setState({
                dragging: false,
                pos: { x: 0, date: null },
            });
        }, 0);
    };

    public onMouseMove = (e: MouseEvent) => {
        if (!this.state.dragging) {
            this.setState({
                dragging: true,
            });
        }
        const relativeX = e.pageX - this.state.rel.x;
        const relativeWidth =
            relativeX /
            (this.props.width - this.padding.right - this.padding.left);
        const daysDiff = this.state.maxX.diff(this.state.minX, 'days');
        const newMinx = this.state.minX
            .clone()
            .add((daysDiff * relativeWidth).toFixed(2), 'days');
        const relativeOldDate = (
            this.state.pos.date || this.state.minX
        ).clone();
        const range = newMinx.diff(relativeOldDate, 'days');
        relativeOldDate.add(range, 'days');
        this.props.onPan(-1 * range);

        this.setState({
            pos: {
                x: e.pageX - this.state.rel.x,
                date: relativeOldDate,
            },
        });
    };

    public onMouseDown = (e: React.MouseEvent<SVGSVGElement>) => {
        if (e.button !== 0) {
            return;
        }
        this.setState(
            {
                rel: {
                    x: e.pageX,
                },
                minX: this.props.minX.clone(),
                maxX: this.props.maxX.clone(),
            },
            () => {
                document.addEventListener('mousemove', this.onMouseMove);
                document.addEventListener('mouseup', this.onMouseUp);
            }
        );
        this.props.onDragChange(true);
        e.stopPropagation();
    };

    public render() {
        if (!this.state.xScale) {
            return null;
        }

        const texts: ITexts = [];
        const sideIndicators: ILineSideIndicator[] = [];
        const actualTodayX = this.state.xScale(this.startOfDay);
        const { expanded } = this.props;
        return (
            this.isLoading() && (
                <div
                    className={cx([
                        'gq-chart-container',
                        {
                            expanded: expanded,
                            'annotation-mode': this.props.annoMode,
                        },
                    ])}
                    style={{
                        ...this.props.style,
                        pointerEvents: 'all',
                        position: 'relative',
                    }}
                    onMouseMove={this._insightHover}>
                    {this.props.annoMode && (
                        <div
                            className="gq-chart-insight-indicator gq-insight-hover-bar"
                            style={{
                                width: 1,
                                left: this.state.insightIndicator.x1,
                                top: Math.min(
                                    this.state.insightIndicator.y1,
                                    this.state.insightIndicator.y2
                                ),
                                height: Math.abs(
                                    this.state.insightIndicator.y2 -
                                        this.state.insightIndicator.y1
                                ),
                            }}
                        />
                    )}
                    {this.props.annoMode && (
                        <div
                            className="gq-chart-insight-indicator gq-inight-hover-dot"
                            style={{
                                width: 5,
                                height: 5,
                                left: this.state.insightIndicator.x1 - 2.5,
                                top: this.state.insightIndicator.y1 - 2.5,
                            }}
                        />
                    )}
                    <svg
                        className={cx([
                            'gq-chart',
                            {
                                'no-pointer-events': this.props.annoMode,
                            },
                        ])}
                        width={
                            expanded
                                ? this.props.width - this.padding.right + 15
                                : this.props.width - (this.padding.right + 15)
                        }
                        height={Math.max(
                            0,
                            this.props.hasToolbar
                                ? this.props.height
                                : this.props.height + this.padding.bottom
                        )}
                        onWheel={this.onMouseWheel}
                        onMouseDown={this.onMouseDown}
                        ref={el => (this.svgRef = el)}
                        data-dragging={this.state.dragging}>
                        <g
                            className="xAxis"
                            transform={`translate(0,${this.props.height -
                                xaxisHeight})`}
                            ref={el => (this.xAxisRef = el)}
                        />
                        <g
                            className={`yAxis ${expanded ? 'expanded' : ''}`}
                            transform={`translate(${
                                expanded ? this.padding.left : 0
                            },0)`}
                            ref={el => (this.yAxisRef = el)}
                        />
                        <g
                            className={`externalDatayAxis ${
                                expanded ? 'expanded' : ''
                            } ${this.props.data.length <= 1 ? 'hidden' : ''}`}
                            transform={`translate(${
                                expanded
                                    ? this.props.width -
                                      (this.padding.right + 18)
                                    : 0
                            },0)`}
                            ref={el => (this.externalDatayAxisRef = el)}
                        />
                        {this.props.data.map(itemData =>
                            this.drawChartGroups(
                                itemData,
                                texts,
                                sideIndicators
                            )
                        )}
                        {actualTodayX >= this.padding.left && (
                            <g
                                className="chart-actual-today"
                                transform={`translate(${actualTodayX},0)`}>
                                <rect
                                    className="actual-today-bar"
                                    height={Math.max(
                                        0,
                                        this.props.height -
                                            xaxisHeight -
                                            this.padding.top
                                    )}
                                    y={this.padding.top}
                                />
                                <text
                                    textAnchor="center"
                                    className="chart-actual-today-text"
                                    dy={15}
                                    dx={
                                        this.props.hasToolbar
                                            ? -(
                                                  this.props.height -
                                                  xaxisHeight -
                                                  10
                                              )
                                            : -(
                                                  this.props.height / 2 -
                                                  this.padding.bottom
                                              )
                                    }>
                                    Today
                                </text>
                            </g>
                        )}
                        {this.props.showVirtualToday && (
                            <Draggable
                                axis="x"
                                bounds={{
                                    left: this.state.xScale(this.props.minX),
                                    right: this.state.xScale(this.props.maxX),
                                    top: 0,
                                    bottom: 0,
                                }}
                                onDrag={this.onVirtualTodayDrag}
                                onStop={this.onVirtualTodayDragStops}
                                position={{
                                    x: this.state.xScale(
                                        this.props.virtualToday
                                    ),
                                    y: 0,
                                }}>
                                <g
                                    className="chart-virtual-today"
                                    transform={`translate(${this.state.xScale(
                                        this.props.virtualToday
                                    )},0)`}>
                                    <rect
                                        className="virtual-today-bar"
                                        y="12"
                                        height={Math.max(
                                            0,
                                            this.props.height - 32
                                        )}
                                    />
                                    <g className="virtual-today-text-container">
                                        <rect
                                            width="89px"
                                            height="26px"
                                            rx="3px"
                                            ry="3px"
                                            fill="#40536a"
                                            x={-44.5}
                                            y={11}
                                        />
                                        <text
                                            className="virtual-today-text"
                                            dy={28}
                                            dx={-36}>
                                            {this.props.virtualToday.format(
                                                MOMENTJS_DATE_DISPLAY_FORMAT
                                            )}
                                        </text>
                                    </g>
                                    <g
                                        className="virtual-today-pin-group"
                                        id="inspectorRect"
                                        transform={`translate(0, ${this.props
                                            .height / 2})`}>
                                        <rect
                                            className="virtual-today-pin-outer-bar"
                                            x="-3.5px"
                                            width="7px"
                                            height="15px"
                                        />
                                        <rect
                                            className="virtual-today-pin-inner-bar"
                                            width="1px"
                                            height="9px"
                                            x="1.25px"
                                            y="3"
                                        />
                                        <rect
                                            className="virtual-today-pin-inner-bar"
                                            width="1px"
                                            height="9px"
                                            x="-1.25px"
                                            y="3"
                                        />
                                    </g>
                                    {this.props.expanded &&
                                        _.map(texts, t => (
                                            <g
                                                key={t.key}
                                                transform={`translate(0,${t.yPosition -
                                                    11})`}
                                                    >
                                                <circle
                                                    r="3"
                                                    fill="#fff"
                                                    cy={11}
                                                />
                                                <text
                                                    className="graph-today-value"
                                                    dx={7}>
                                                    {typeof t.yValue ===
                                                    'number'
                                                        ? t.yValue.toFixed(2)
                                                        : null}
                                                </text>
                                            </g>
                                        ))}
                                    <rect
                                        className="virtual-today-dragArea"
                                        height={Math.max(0, this.props.height)}
                                        width="10"
                                        transform="translate(-5,0)"
                                    />
                                </g>
                            </Draggable>
                        )}
                    </svg>
                    {sideIndicators.map((item, index) => {
                        return (
                            <item.component
                                key={index}
                                {...item.componentProps}
                            />
                        );
                    })}
                </div>
            )
        );
    }

    private resetShowCirclesTimer = () => {
        clearTimeout(this.showCirclesTimer);
        this.showCirclesTimer = setTimeout(
            this.setState.bind(this),
            SHOW_CIRCLES_DELAY,
            { showCirclesTimerEnded: true }
        );
    };

    private getXScale() {
        const xScale = d3.scaleTime();
        xScale
            .range([
                this.props.expanded ? this.padding.left : 0,
                this.props.width -
                    (this.props.expanded
                        ? this.padding.right + 35
                        : this.padding.right + 35),
            ])
            .domain([this.props.minX, this.props.maxX]);
        return xScale;
    }

    private getYScale(itemData: IChartData, lineIndex: number) {
        const yScale = d3.scaleLinear();
        yScale.range([
            (this.props.hasToolbar
                ? this.props.height
                : this.props.height - this.padding.bottom) - xaxisHeight,
            this.padding.top,
        ]);

        const scale = this.props.expanded
            ? itemData.domainY.expanded
            : itemData.domainY.minimized;
        if (scale === E_DOMAIN_Y_SCALE.shared) {
            yScale.domain([itemData.minValue, itemData.maxValue]);
        } else {
            yScale.domain([
                itemData.lines[lineIndex].minValue,
                itemData.lines[lineIndex].maxValue,
            ]);
        }

        return yScale;
    }

    private onVirtualTodayDrag = (e: DraggableEvent, data: DraggableData) => {
        e.stopPropagation();
        if (!this.props.changeDataWhenDragStop) {
            const scale = this.state.xScale;
            const newVToday = moment(scale.invert(data.lastX));
            if (
                newVToday.isSameOrAfter(this.props.minX) &&
                newVToday.isSameOrBefore(this.props.maxX)
            ) {
                this.props.onVirualTodayChange(newVToday);
            }
        }
    };
    private onVirtualTodayDragStops = (
        e: DraggableEvent,
        data: DraggableData
    ) => {
        if (this.props.changeDataWhenDragStop) {
            const scale = this.state.xScale;
            let newVToday = moment(scale.invert(data.lastX));
            if (newVToday.hour() >= 12) {
                newVToday = newVToday.startOf('day').add(1, 'day');
            } else {
                newVToday = newVToday.startOf('day');
            }
            if (
                newVToday.isSameOrAfter(this.props.minX) &&
                newVToday.isSameOrBefore(this.props.maxX)
            ) {
                this.props.onVirualTodayChange(newVToday);
            }
        }
    };

    private onMouseWheel = (e: React.WheelEvent<SVGSVGElement>) => {
        if (e.deltaX !== 0) {
            const relativeWidth =
                e.deltaX /
                (this.props.width -
                    (this.props.expanded
                        ? this.padding.right
                        : this.padding.right + 20) -
                    this.padding.left);
            const pan = Math.round(
                relativeWidth * this.props.maxX.diff(this.props.minX, 'days')
            );
            this.props.onPan(pan);
            // else because we dont want both and we prefer x
        } else if (e.deltaY !== 0) {
            this.props.onWheel(e.deltaY < 0);
        }
    };

    private isLoading() {
        return this.props.width !== null;
    }

    private isSharedDomain(datum: IChartData) {
        return (
            (this.props.expanded
                ? datum.domainY.expanded
                : datum.domainY.minimized) === E_DOMAIN_Y_SCALE.shared
        );
    }

    private drawChartGroups(
        itemData: IChartData,
        texts: ITexts,
        sideIndicators: ILineSideIndicator[]
    ) {
        const shared = this.isSharedDomain(itemData);

        return (
            <g className="line-container" key={itemData.identifier}>
                {itemData.lines.map((line, i) => {
                    const yScale = this.state.yScales[
                        shared
                            ? itemData.identifier
                            : `${itemData.identifier}_${line.identifier}`
                    ];
                    const xScale = this.state.xScale;
                    if (!yScale || !xScale) {
                        return null;
                    }

                    return (
                        <g key={line.identifier} className="chart-group">
                            {this.props.showLines && (
                                <path
                                    strokeWidth={2}
                                    className={`chart-line ${line.className ||
                                        ''}`}
                                    d={line.draw(yScale, xScale)}
                                    {...line.props}
                                    stroke={line.props.stroke}
                                />
                            )}
                            {this.props.expanded &&
                                this.populateText(line, texts, yScale)}
                            <g className="chart-group-circles">
                                {this.props.showCircles &&
                                    this.props.expanded &&
                                    this.state.showCirclesTimerEnded &&
                                    [
                                        E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
                                        E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
                                    ].includes(itemData.identifier) &&
                                    line.identifier &&
                                    !line.identifier.match(/proj/i) &&
                                    this.renderLineCirclesByZoom(line, yScale)}
                            </g>
                            {this.props.expanded &&
                                line.sideIndicator &&
                                sideIndicators.push({
                                    component: line.sideIndicator.component,
                                    componentProps: {
                                        isRight: true,
                                        x: this.padding.right - 24,
                                        y:
                                            yScale(
                                                get(
                                                    'yValue',
                                                    line.getValue(
                                                        this.props.maxX.format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    )
                                                )
                                            ) - 4,
                                        ...line.sideIndicator.componentProps,
                                    },
                                }) &&
                                undefined}
                            {/*line.sideIndicator && line.sideIndicator.component(line.sideIndicator.componentProps)*/}
                        </g>
                    );
                })}
            </g>
        );
    }
    private _insightHover = (e: React.MouseEvent<HTMLDivElement>) => {
        e.persist();
        this.insightHoverDebounce(e);
    };

    private insightHover = (e: React.MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY } = e;
        if (!this.props.annoMode || !this.svgRef) {
            return;
        }
        const svgRect = this.svgRef.getBoundingClientRect();
        GQPopupStorage.addData(EDITOR_DATE_TOOLTIP, {
            element: null,
            fixedPos: {
                left: clientX,
                top: clientY,
            },
            options: {
                noDiamond: true,
            },
            tooltipData: (
                <div className="gq-insight-editor-date-tooltip">
                    {moment(
                        this.state.xScale.invert(clientX - svgRect.left)
                    ).format(MOMENTJS_DATE_DISPLAY_FORMAT)}
                </div>
            ),
        });
        const closestLine = this.getClosestLine(
            clientX - svgRect.left,
            clientY - svgRect.top
        );
        if (!closestLine) {
            return;
        }
        runInAction(() => {
            annoStore.closestLine = closestLine;
        });
        this.setState({
            insightIndicator: {
                x1: clientX - svgRect.left,
                y2: clientY - svgRect.top,
                y1: this.state.yScales[closestLine.identifier](
                    closestLine.yVal
                ),
            },
        });
    };

    private getClosestLine(x: number, y: number): IClosestChartLine {
        if (isEmpty(this.state.yScales) || !this.state.xScale) {
            return null;
        }
        const { data } = this.props;

        const inputNormalizer = d3
            .scaleLinear()
            .range([this.props.height - xaxisHeight, this.padding.top])
            .domain([0, 100]);
        const date = moment.min(
            C_TIMELINE_MAX_DATE,
            moment.max(C_TIMELINE_MIN_DATE, moment(this.state.xScale.invert(x)))
        );
        const nextDay = date
            .clone()
            .startOf('day')
            .add(1, 'days');
        let inputY = inputNormalizer.invert(y);
        inputY = Math.max(0, Math.min(inputY, 100));
        let dObj: IClosestChartLine = null;
        data.forEach(datum => {
            const shared = this.isSharedDomain(datum);
            datum.lines.forEach((line, i) => {
                if (
                    !includes(line.chartType, [
                        E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
                        E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
                    ])
                ) {
                    return;
                }

                const identifier = shared
                    ? datum.identifier
                    : `${datum.identifier}_${line.identifier}`;
                const scale = this.state.yScales[identifier];
                const val = {
                    ...line.getValue(date.format(C_SCORES_DATE_FORMAT)),
                };
                const nextDayVal = line.getValue(
                    nextDay.format(C_SCORES_DATE_FORMAT)
                );
                if (isEmpty(nextDayVal) || isEmpty(val)) {
                    return;
                }

                const delta = nextDayVal.yValue - val.yValue;
                const diff = date.diff(date.clone().startOf('day'), 'minutes');
                val.yValue += delta * (diff / (24 * 60));
                inputNormalizer.domain(scale.range());
                inputNormalizer.range([0, 100]);
                const normalized = inputNormalizer(scale(val.yValue));
                const distance = Math.abs(normalized - inputY);
                if (!dObj || distance < dObj.distance) {
                    dObj = {
                        distance,
                        yVal: val.yValue,
                        identifier,
                        activeDate: date,
                        type: datum.identifier,
                        country: line.country,
                    };
                }
            });
        });
        return dObj;
    }

    private renderLineCirclesByZoom(
        line: IChartLine,
        yScale: d3.ScaleLinear<number, number>
    ): JSX.Element[] {
        const circlesByDate: {
            [dateStr: string]: IChartCircle;
        } = {};
        const xScale = this.state.xScale;
        const circlesByImpact: {
            [impact: number]: {
                [dateStr: string]: IChartCircle;
            };
        } = {};
        for (
            const curDate = this.props.minX.clone().startOf('day');
            curDate.isSameOrBefore(this.props.maxX);
            curDate.add(1, 'days')
        ) {
            const dateStr = curDate.format(C_SCORES_DATE_FORMAT);
            const curCircle = get(['values', dateStr, 'circle'], line);
            if (curCircle) {
                const roundedImpact = Math.round(curCircle.impact * 100) / 100;
                if (!circlesByImpact[roundedImpact]) {
                    circlesByImpact[roundedImpact] = {};
                }
                circlesByImpact[roundedImpact][dateStr] = curCircle;
            }
        }
        const datesWithCirclesSorted: string[] = [];
        Object.keys(circlesByImpact)
            .sort()
            .reverse()
            .forEach(circleImpact => {
                const impactCirclesByDate =
                    circlesByImpact[Number(circleImpact)];
                _.forEach(impactCirclesByDate, (circle, dateStr) => {
                    const nextDateIndex = sortedArrayInsertIndex(
                        datesWithCirclesSorted,
                        dateStr
                    );
                    const nextDate = moment(
                        datesWithCirclesSorted[nextDateIndex]
                    );
                    const prevDate = moment(
                        datesWithCirclesSorted[nextDateIndex - 1]
                    );

                    const closestPrevCircle =
                        circlesByDate[prevDate.format(C_SCORES_DATE_FORMAT)];
                    const closestNextCircle =
                        circlesByDate[nextDate.format(C_SCORES_DATE_FORMAT)];

                    let canAdd = true;
                    if (closestPrevCircle && closestPrevCircle !== circle) {
                        const deltaX =
                            xScale(prevDate) -
                            xScale(line.getValue(dateStr).date);
                        const deltaY =
                            yScale(
                                line.getValue(
                                    prevDate.format(C_SCORES_DATE_FORMAT)
                                ).yValue
                            ) - yScale(line.getValue(dateStr).yValue);
                        const distanceBetweenCircles = Math.sqrt(
                            deltaX * deltaX + deltaY * deltaY
                        );
                        if (
                            distanceBetweenCircles <
                            circle.radius +
                                closestPrevCircle.radius +
                                MIN_PIXEL_PADDING_BETWEEN_CIRCLES
                        ) {
                            canAdd = false;
                        }
                    }

                    if (
                        canAdd &&
                        closestNextCircle &&
                        closestNextCircle !== circle
                    ) {
                        const deltaX =
                            xScale(nextDate) -
                            xScale(line.getValue(dateStr).date);
                        const deltaY =
                            yScale(
                                line.getValue(
                                    nextDate.format(C_SCORES_DATE_FORMAT)
                                ).yValue
                            ) - yScale(line.getValue(dateStr).yValue);
                        const distanceBetweenCircles = Math.sqrt(
                            deltaX * deltaX + deltaY * deltaY
                        );
                        if (
                            distanceBetweenCircles <
                            circle.radius +
                                closestNextCircle.radius +
                                MIN_PIXEL_PADDING_BETWEEN_CIRCLES
                        ) {
                            canAdd = false;
                        }
                    }

                    if (canAdd) {
                        circlesByDate[dateStr] = circle;
                        datesWithCirclesSorted.splice(
                            nextDateIndex,
                            0,
                            dateStr
                        );
                    }
                });
            });
        const res: JSX.Element[] = [];
        _.forEach(circlesByDate, (circle, dateStr) => {
            if (circle) {
                res.push(
                    <circle
                        key={circle.identifier}
                        className={
                            circle.className
                                ? `chart-circle ${circle.className}`
                                : 'chart-circle'
                        }
                        cx={xScale(line.getValue(dateStr).date)}
                        cy={yScale(line.getValue(dateStr).yValue)}
                        r={circle.radius}
                        {...circle.props}
                    />
                );
            }
        });
        return res;
    }

    private populateText(lineData: IChartLine, texts: ITexts, yScale: any) {
        if (lineData.hideYValue === true) {
            return;
        }
        const dataForDate = lineData.getValue(
            this.props.virtualToday.format('YYYY-MM-DD')
        );

        if (this.props.expanded && dataForDate && dataForDate.yValue) {
            texts.push({
                key: `${lineData.identifier}_expanded_value`,
                yValue: dataForDate.yValue,
                yPosition: yScale(dataForDate.yValue),
            });
        }
    }

    private getXAxis(
        fromDate: moment.Moment,
        toDate: moment.Moment,
        xScale: d3.ScaleTime<any, any>
    ) {
        const xAxis = d3.axisBottom(xScale);
        const months = toDate.diff(fromDate, 'months', true);
        let format: (date: Date) => string = d3.timeFormat("%b '%y");
        switch (true) {
            case months <= 1:
                xAxis.ticks(d3.timeDay.every(2));
                format = d3.timeFormat("%b %d, '%y");
                break;
            case months < 2:
                xAxis.ticks(d3.timeWeek.every(1));
                format = d3.timeFormat("%b %d, '%y");
                break;
            case months < 4:
                xAxis.ticks(d3.timeWeek.every(2));
                format = d3.timeFormat("%b %d, '%y");
                break;
            case months < 10:
                xAxis.ticks(d3.timeMonth.every(1));
                break;
            case months < 18:
                xAxis.ticks(d3.timeMonth.every(3));
                break;
            case months < 36:
                xAxis.ticks(d3.timeMonth.every(6));
                break;
            case months > 36:
                format = d3.timeFormat('%Y');
                xAxis.ticks(d3.timeYear.every(1));
                break;
        }
        //@ts-ignore
        //https://stackoverflow.com/questions/55992937/typescript-error-when-using-d3-timeformat-in-axis-tickformat
        xAxis.tickFormat(format);
        return xAxis;
    }

    private renderXAxis = () => {
        const xScale = this.state.xScale;
        const xAxis = this.getXAxis(this.props.minX, this.props.maxX, xScale);
        d3.select(this.xAxisRef).call(xAxis);
    };

    private getLineDataByTypes = ()=>{
        const riskData = filter(
            data =>
                data.identifier === E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
            this.props.data
        );
        const crData = filter(
            data =>
                data.identifier === E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
            this.props.data
        );
        const pulseData = filter(
            data =>
                data.identifier === E_CHART_GROUP_IDENTIFIERS.PULSE,
            this.props.data
        );
        const economicData = filter(
            data => includes(data.identifier, ECONIMIC_INDICATORS_DATA),
            this.props.data
        );

        return {riskData, pulseData, economicData, crData};
    }

    private renderYAxis = () => {
        const yScale = d3.scaleLinear();
        yScale.range([this.props.height - xaxisHeight, this.padding.top]);
        const yAxis = d3.axisLeft(yScale);
        //@ts-ignore
        //https://stackoverflow.com/questions/55992937/typescript-error-when-using-d3-timeformat-in-axis-tickformat
        yAxis.tickFormat(yAxisTickFormatter);

        if (!this.props.expanded) {
            yAxis.ticks(3);
            yAxis.tickSizeInner(
                -(
                    this.props.width -
                    (this.props.expanded
                        ? this.padding.right + 20
                        : this.padding.right + 20)
                )
            );
            d3.select(this.yAxisRef).call(yAxis);
            return;
        }
        yAxis.ticks(Y_AXIS_NUM_TICKS);
        const {riskData, pulseData, economicData, crData} = this.getLineDataByTypes();
        switch (true) {
            case !isEmpty(crData):
                yScale.domain([crData[0].minValue, crData[0].maxValue]);
                break;
            case !isEmpty(riskData):
                yScale.domain([riskData[0].minValue, riskData[0].maxValue]);
                break;
            case isEmpty(riskData) && !isEmpty(pulseData):
                yScale.domain([pulseData[0].minValue, pulseData[0].maxValue]);
                break;
            case isEmpty(riskData) &&
                isEmpty(pulseData) &&
                !isEmpty(economicData):
                yScale.domain([
                    economicData[0].minValue,
                    economicData[0].maxValue,
                ]);
                break;
        }

        yAxis.scale(yScale);
        yAxis.tickSizeInner(
            -(
                this.props.width -
                (this.padding.left +
                    (this.props.expanded
                        ? this.padding.right + 20
                        : this.padding.right + 20))
            )
        );
        d3.select(this.yAxisRef).call(yAxis);
    };
    private renderExternalDataYAxis = () => {
        const yScale = d3.scaleLinear();
        yScale.range([this.props.height - xaxisHeight, this.padding.top]);
        const yAxis = d3.axisRight(yScale);
        //@ts-ignore
        //https://stackoverflow.com/questions/55992937/typescript-error-when-using-d3-timeformat-in-axis-tickformat
        yAxis.tickFormat(yAxisTickFormatter);
        if (!this.props.expanded) {
            yAxis.ticks(3);
            yAxis.tickSizeInner(
                -(
                    this.props.width -
                    (this.props.expanded
                        ? this.padding.right + 10
                        : this.padding.right + 15)
                )
            );
            d3.select(this.externalDatayAxisRef).call(yAxis);
            return;
        }

        // shai - this value was 7 before i changed it,
        // is there any good reason why it wasn't 10 ? who not be exactly the same as y axis?
        yAxis.ticks(Y_AXIS_NUM_TICKS);
        const {riskData, pulseData, economicData} = this.getLineDataByTypes();
        switch (true) {
            case !isEmpty(riskData) && !isEmpty(pulseData):
                yScale.domain([pulseData[0].minValue, pulseData[0].maxValue]);
                break;
            case isEmpty(pulseData) && !isEmpty(economicData):
            case isEmpty(riskData) &&
                !isEmpty(pulseData) &&
                !isEmpty(economicData):
                yScale.domain([
                    economicData[0].minValue,
                    economicData[0].maxValue,
                ]);
                break;
        }
        yAxis.scale(yScale);
        yAxis.tickSizeInner(3);
        d3.select(this.externalDatayAxisRef).call(yAxis);
    };
}
