import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { IMobxRootState } from '../../../RootStore';
import PastScoreItem from './GQPastScoreItem';
import { IEventUScore } from '../../../interfaces';
import GQButton from '../../GQButton/GQButton';
import { isEmpty } from 'lodash/fp';

export type IGQPastScoreListProps = {
    title: string;
    scores: IEventUScore[];
    canUserEdit: boolean;
    canUserDelete: boolean;
    onEdit?: (score: IEventUScore) => void;
    onDelete?: (score: IEventUScore) => void;
    toggleShowAllScoresMenu?: (currentButtonRef: HTMLElement) => void;
    isMenuOpen?: boolean;
    editMode?: boolean;
    organizationId?: string;
};

const GQPastScoreList = inject(({ UserStore }: IMobxRootState) => {
    return {
        organizationId: UserStore.organization.id,
    };
})(
    ({
        title,
        scores,
        editMode,
        canUserEdit,
        canUserDelete,
        onDelete,
        onEdit,
        isMenuOpen,
        toggleShowAllScoresMenu,
        organizationId,
    }: IGQPastScoreListProps) => {
        const [isItemMenuOpen, setIsItemMenuOpen] = useState(false);
        let buttonRef: HTMLElement;

        if (isEmpty(scores)) return null;

        const canEdit = (uscoreOrganizationId: string) => {
            return canUserEdit && organizationId === uscoreOrganizationId;
        };

        const canDelete = (uscoreOrganizationId: string) => {
            return canUserDelete && organizationId === uscoreOrganizationId;
        };

        const onDeleteScore = (uscore: IEventUScore) => {
            setIsItemMenuOpen(false);
            onDelete(uscore);
        };

        const onEditScore = (uscore: IEventUScore) => {
            setIsItemMenuOpen(false);
            onEdit(uscore);
        };

        const setMenuVisibility = (isVisible: boolean) => {
            setIsItemMenuOpen(isVisible);
        };

        const handleButtonClick = () => {
            setIsItemMenuOpen(false);
            toggleShowAllScoresMenu(buttonRef);
        };

        return (
            <div className="past-score-list">
                <div className="past-score-header">
                    <span>{title}</span>
                    {canUserDelete && (
                        <div ref={(el) => (buttonRef = el)}>
                            <GQButton
                                icon={isMenuOpen ? "gqi-close" : "gqi-ellipsis-v"}
                                noBorder={true}
                                onClick={handleButtonClick}
                                noPadding={true}
                                style={{ marginLeft: 10 }}
                            />
                        </div>
                    )}
                </div>
                {scores.map((score: IEventUScore) => (
                    <PastScoreItem
                        pastScoreItem={score}
                        key={score.score_id}
                        canEdit={canEdit(score.organization_id)}
                        canDelete={canDelete(score.organization_id)}
                        onDelete={onDeleteScore}
                        onEdit={onEditScore}
                        canShowMenu={!isItemMenuOpen}
                        notifyMenuDispaly={setMenuVisibility}
                        editMode={editMode}
                    />
                ))}
            </div>
        );
    }
);

export default GQPastScoreList;
