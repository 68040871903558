import React from 'react';
import { IEvent } from '../../interfaces';
import * as _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import GQFlag from './../GQFlag';
import { durationOptions, effectOptions } from './consts';
import CustomDropDown from './lib/CustomDropDown';
import classNames from 'classnames';
import GQEventService, {
    EventSubscription,
} from '../../services/EventsService';
import APIService, { IActiveCountry } from '../../services/APIService';
import { observer, inject } from 'mobx-react';
import rootStore, { IMobxRootState } from '../../RootStore';
import GQButton from '../GQButton/GQButton';
import GQLoader from '../GQLoader/GQLoader';
import ScoreArticle from '../GQScoreArticle/GQScoreArticle';
import { flow, split, get } from 'lodash/fp';
import { mapWithKeys } from 'utils/lodashUtils';

interface State {
    showExpanded: boolean;
    showDropDown: boolean;
    articleText: string;
    isLoadingContent: boolean;
    showUrlFallback: boolean;
    isEditing: boolean;
    editingId: string;
    submitInProgress: boolean;
    hasError: boolean;
    error: string;
    hideMenu: boolean;
    trashCan: Set<string>;

    formCountryId: number;
    formImpact: number;
    formDuration: number;
    draggedImpact?: number;
    draggedDuration?: number;
    editFlag: boolean;
}
export interface IScoreEventProps {
    close: () => void;
    getData: (id: string) => void;
    scoreDisabled?: boolean;
    currentDimension?: number;
    event?: IEvent;
    isDebug?: boolean;
    isLoadingArticle: boolean;
    countries?: IActiveCountry[];
    licensedCountriesArr?: IActiveCountry[];
    canUserScore: boolean;
    canUserEditScore: boolean;
    canUserDeleteScore: boolean;
}

type Props = IScoreEventProps & RouteComponentProps<{ id: string }>;

@inject(
    ({
        risksStore,
        scoreEventStore,
        appStore,
        countryStore,
    }: IMobxRootState) => {
        return {
            currentDimension: _.first(risksStore.currentList),
            scoreDisabled: false,
            event: scoreEventStore.activeEvent,
            isDebug: appStore.debugMode,
            countries: countryStore.countries.countries,
            licensedCountriesArr: countryStore.countries.licensedCountriesArr,
        };
    }
)
@observer
class ScoreEvent extends React.Component<Props, State> {
    private componentIsMounted: boolean = false;
    private onMouseLeftClickSubscription: EventSubscription = null;
    private onKeyboardEscClick: EventSubscription = null;
    private dropdownX = 0;
    private dropdownY = 0;
    private isFirstLoadFlag = true;
    private originalCountires = rootStore.countryStore.currentCountryList;
    constructor(props: Props) {
        super(props);
        this.state = {
            showExpanded: true,
            showDropDown: false,
            articleText: null,
            showUrlFallback: false,
            editingId: null,
            formCountryId: null,
            formDuration: 1,
            formImpact: 0,
            hasError: false,
            error: null,
            submitInProgress: false,
            isEditing: false,
            draggedImpact: null,
            draggedDuration: null,
            editFlag: false,
            hideMenu: null,
            trashCan: new Set(),
            isLoadingContent: false,
        };
        this.onCountryPickClick = this.onCountryPickClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.getToastText = this.getToastText.bind(this);
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
        if (this.onMouseLeftClickSubscription) {
            this.onMouseLeftClickSubscription.remove();
        }
        if (this.onKeyboardEscClick) {
            this.onKeyboardEscClick.remove();
        }
    }

    public componentDidMount() {
        this.componentIsMounted = true;
        this.onMouseLeftClickSubscription = GQEventService.registerOnMouseLeftClick(
            this.onLeftMouseClick
        );
        this.onKeyboardEscClick = GQEventService.registerOnEscKeyboardClick(
            this.onEscape
        );
        this.props.getData(this.URLEventId);
    }

    public get URLEventId() {
        return _.get(this.props, 'match.params.id');
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.URLEventId !== prevProps.match.params.id) {
            this.props.getData(this.URLEventId);
        }

        let singleCountry: number = null;
        let state: any = {};
        let stateFlag = false;
        let currentCountry: number = this.state.formCountryId;
        if (!_.isEqual(prevProps.event, this.props.event)) {
            rootStore.chartStore.setState({
                expanded: false,
            });
            if (this.props.event !== null) {
                if (this.isFirstLoadFlag) {
                    const countries = this.originalCountires;
                    if (countries.length === 1) {
                        const id = countries[0];
                        if (_.find(this.props.event.countries, id)) {
                            currentCountry = id;
                        }
                    }
                } else {
                    singleCountry =
                        this.props.event.countries.length === 1
                            ? _.first(this.props.event.countries)
                            : null;
                    if (
                        singleCountry === null &&
                        this.props.event !== null &&
                        currentCountry !== null
                    ) {
                        const nextEventHasCountry = _.find(
                            this.props.event.countries,
                            this.state.formCountryId
                        );
                        currentCountry = nextEventHasCountry
                            ? this.state.formCountryId
                            : undefined;
                    } else if (singleCountry !== null) {
                        currentCountry = singleCountry;
                    } else {
                        currentCountry = undefined;
                    }
                }
                this.isFirstLoadFlag = false;
            }
            state = {
                ...state,
                ...(this.initialState() as any),
                formCountryId: currentCountry,
            };
            stateFlag = true;
            delete state.articleText;
            delete state.showUrlFallback;
            this.loadEventContent();
        }
        if (this.shouldUpdateFormByEventSuggestions(prevProps, prevState)) {
            const filtered = _.filter(this.props.event.suggestions, e => {
                return (
                    e.dimension_id === this.props.currentDimension &&
                    e.country_id === this.state.formCountryId
                );
            });
            if (filtered.length) {
                const suggestion = _.first(filtered);
                let suggestionDuration: number = 0;
                for (const i in durationOptions) {
                    if (
                        durationOptions[i].isBelong(
                            suggestion.suggested_duration
                        )
                    ) {
                        suggestionDuration = durationOptions[i].value;
                        break;
                    }
                }
                let suggestionImpact = 0;
                for (const i in effectOptions) {
                    if (effectOptions[i].isBelong(suggestion.suggested_value)) {
                        suggestionImpact = effectOptions[i].value;
                        break;
                    }
                }
                state = {
                    ...state,
                    draggedImpact: suggestionImpact,
                    draggedDuration: suggestionDuration,
                };
                stateFlag = true;
            }
        }
        if (stateFlag) {
            this.setState(state);
        }
    }

    public setState<K extends keyof State>(
        state: Pick<State, K>,
        cb?: () => void
    ) {
        if (this.componentIsMounted) {
            super.setState(state, cb);
        }
    }
    public render() {
        return (
            <div className={classNames(['article-container', 'col'])}>
                <div className="button-container">
                    <GQButton
                        icon="gqi-close"
                        noBorder={true}
                        onClick={this.onClose}
                        tooltip="Close article"
                        tooltipPosition="bottom right"
                    />
                </div>
                {!this.showLoader() && (
                    <CustomDropDown
                        show={this.state.showDropDown}
                        left={this.dropdownX}
                        top={this.dropdownY}
                        activeIDs={[this.state.formCountryId]}
                        options={this.props.event.countries.map(
                            c => this.props.countries[c]
                        )}
                        onClick={this.onItemClick}
                    />
                )}
                {this.showLoader() ? (
                    <div className="article-loader">
                        <GQLoader />
                    </div>
                ) : (
                    <div
                        className={classNames(['article-content', 'row'], {
                            loading: this.props.event == null,
                        })}>
                        <div className="left-wrapper">
                            <div className="article-left">
                                <div className="article-header-container">
                                    <div className="article-header">
                                        {this.props.event &&
                                            this.props.event.headline}
                                    </div>
                                    <div className="article-subheader">
                                        <div className="article-details">
                                            <span className="article-source">
                                                {this.props.event &&
                                                    this.props.event.source}
                                            </span>
                                            <span className="article-date">
                                                {this.props.event &&
                                                    this.props.event.time.format(
                                                        'MMM Do YY'
                                                    )}
                                            </span>
                                        </div>

                                        <div className="article-flags">
                                            {this.props.event &&
                                                this.props.event.countries.map(
                                                    c => (
                                                        <GQFlag
                                                            countryId={c}
                                                            key={c}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                    {this.getLink() && (
                                        <div className="article-subheader">
                                            <div
                                                className="article-details"
                                                onClick={this.goToArticle}>
                                                <span className="article-link">
                                                    [See Original Article]
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="divider" />
                                <div className="article-text" id="articleText">
                                    {this.state.isLoadingContent ? (
                                        <GQLoader />
                                    ) : (
                                        this.renderArticleText()
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames([
                                'right-wrapper',
                                { 'scroll-expanded': this.state.showExpanded },
                            ])}>
                            <ScoreArticle
                                canUserDeleteScore={this.props.canUserDeleteScore}
                                canUserEditScore={this.props.canUserEditScore}
                                canUserScore={this.props.canUserScore}
                                event={this.props.event}
                                countriesList={this.props.licensedCountriesArr}
                            />
                            <div className="article-right">
                                {this.props.event &&
                                    this.props.event.uscores && (
                                        <div
                                            id="pastScores"
                                            className="article-right-section"
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private getLink = () => {
        return get('event.url', this.props);
    };
    private goToArticle = () => {
        const url = this.getLink();
        if (url) {
            window.open(url, '_blank');
        }
    };

    private showLoader() {
        return (
            !this.props.event ||
            !this.props.countries ||
            this.props.isLoadingArticle
        );
    }

    private onEscape = () => {
        if (this.state.isEditing) {
            this.setState({
                isEditing: false,
                editingId: null,
                editFlag: false,
            });
        } else {
            this.onClose();
        }
    };

    private renderArticleText() {
        if (this.props.event && this.state.articleText) {
            const { articleText: text } = this.state;
            if (text.includes('\n')) {
                return flow(
                    split('\n'),
                    mapWithKeys((articleParagraph, index) => (
                        <p key={index}>{articleParagraph}</p>
                    ))
                )(text);
            }

            return flow(
                split('. '),
                mapWithKeys((articleParagraph, index) => (
                    <p key={index}>{articleParagraph}.</p>
                ))
            )(text);
        }

        if (
            this.props.event &&
            this.state.articleText === null &&
            this.state.showUrlFallback
        ) {
            if (this.props.event.url) {
                return (
                    <p>
                        <a href={this.props.event.url} target="_blank" rel="noopener noreferrer">
                            Click here to view the article
                        </a>
                    </p>
                );
            }
            return 'Unable to load the article text';
        }
        return null;
    }

    private onClose = () => {
        this.props.close();
    };
    private getToastText() {
        return this.state.hasError ? this.state.error : 'Score received!';
    }

    private shouldUpdateFormByEventSuggestions(
        prevProps: IScoreEventProps,
        prevState: State
    ): boolean {
        if (this.props.event === null) {
            return false;
        }
        if (this.state.editFlag) {
            return false;
        }
        if (prevProps.currentDimension !== this.props.currentDimension) {
            return true;
        }
        if (prevProps.event === null) {
            return true;
        }
        if (prevState.formCountryId !== this.state.formCountryId) {
            return true;
        }
        return prevProps.event.id !== this.props.event.id;
    }

    private onItemClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        const el = e.target as HTMLDivElement;
        this.setState({
            showDropDown: false,
            formCountryId: Number(el.id),
        });
    }

    private onCountryPickClick(e: React.MouseEvent<HTMLSpanElement>) {
        e.stopPropagation();
        this.dropdownX = e.pageX;
        this.dropdownY = e.pageY;
        this.setState({
            showDropDown: true,
        });
    }

    private onLeftMouseClick = () => {
        if (this.state.showDropDown) {
            this.setState({ showDropDown: false });
        }
    };

    private async loadEventContent() {
        if (!this.props.event) {
            return;
        }
        const { article_text } = this.props.event || { article_text: null };
        const id = this.props.match.params.id;
        if (!article_text) {
            this.setState({
                articleText: null,
                showUrlFallback: true,
            });
            return;
        }

        try {
            this.setState({
                articleText: '',
                isLoadingContent: true,
            });
            const articleText = await APIService.getEventContent(article_text);
            if (this.props.match.params.id === id && this.componentIsMounted) {
                this.setState({
                    articleText,
                    showUrlFallback: false,
                    isLoadingContent: false,
                });
            }
        } catch (err) {
            this.setState({
                showUrlFallback: true,
                isLoadingContent: false,
            });
        }
    }
    private initialState(): Partial<State> {
        return {
            articleText: null,
            showUrlFallback: false,
            editingId: null,
            formDuration: 1,
            formImpact: 0,
            hasError: false,
            error: null,
            isEditing: false,
            draggedImpact: null,
            draggedDuration: null,
            editFlag: false,
        };
    }
}

export default ScoreEvent;
