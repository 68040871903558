import { Moment } from 'moment';
import { ICircleData } from '././../../interfaces';
import { EVENTS_FILTER } from '../../components/eventsFeed/EventsFeedInterfaces';
import { Descendant } from 'slate';
import { HeatmapPercentile, HeatmapScoreType } from 'components/Heatmap/HeatmapTypes';
import { E_TIME_PRESET_KEYS } from '../../constants';
export interface IAPIScoreDelta {
    scores: Array<{
        score_today: number;
        delta: number;
        country_id: number;
        score_size: number;
        delta_color: {
            type: string;
            percent: number;
        };
    }>;
    dimensionID: number;
}

export type IAPIScores = number[];
export interface IAPIScoresByCountry {
    [countryId: number]: IAPIScores;
}

export interface IAPIMaxImpactMap {
    [dayOffset: number]: number;
}

export interface IGlobalTopRisksDelta {
    riskId: number;
    riskType: E_RISK_TYPE;
    country: IActiveCountry;
    delta: number;
}

export interface IGetPredictionsFeed {
    abbreviation: string;
    call_date: Date;
    confidence: string;
    consensus: string;
    country_id: number;
    description: string;
    id: number;
    outcome: string;
    realization_date: string;
    tags: string;
    window: string;
}
export interface IScoresDelta {
    scores: ICircleData[];
    scoresOriginal: any;
    identifier: number;
    type: E_RISK_TYPE;
}
export interface IActiveCountry {
    name: string;
    abbreviation: string;
    abbreviation_short: string;
    latitude: number;
    longitude: number;
    id: number;
    currency_code: string;
}
export interface IContingentNode {
    name: string;
    abbreviation_short: string;
    latitude: number;
    longitude: number;
    id: number;
    currency_code: string;
    x: number;
    y: number;
    vx: number;
    vy: number;
}

export interface IActiveCountryMap {
    [countryID: number]: IActiveCountry;
}

export interface IActiveCountryAPI {
    countries: IActiveCountryMap;
    licensed: IActiveCountryMap;
    all: IActiveCountryMap;
}

export interface IActiveDimension {
    id: number;
    xlname: string;
    tier: number;
    sname: string;
    lname: string;
    scorable: false;
    hidden?: boolean;
    definition?: string;
}

export interface IActiveDimensionTree extends IActiveDimension {
    children?: IActiveDimensionTree[];
}

export interface IActiveDimensionAPI {
    flatten: IActiveDimension[];
    tree: IActiveDimensionTree;
    fullTree: IActiveDimensionTree;
    hashed: { [dimensionId: number]: IActiveDimension };
    allDimensions: IActiveDimension[];
}

export interface IGETScoreDimensionChartParams {
    from?: Date | Moment;
    dimensionID: number;
    clientFacingIndicatorID: number;
    countryID?: number | number[];
    until?: Date | Moment | string;
    isClientFacingIndicator: boolean;
}

export interface IGETScoreDimensionChartCountryModeParams extends ITimeRange {
    countryID: number;
    dimensionIds: number[];
    clientFacingIndicatorsIds: number[];
    isClientFacingIndicator: boolean;
}
export interface IScoreDimensionChartDataValue {
    date: Moment | string;
    [country: string]: any;
}
export interface IScoreDimensionChartData {
    labels: {
        countries: string[];
        xKey: string;
    };
    chartData: IScoreDimensionChartDataValue[];
}

export interface IEventUScore {
    country_id: number;
    dimension_id: number;
    impact: number;
    duration: number;
    score_id: string;
    organization_id: string;
    human?: boolean;
}

export interface IBaseEvent {
    id: number;
    headline: string;
    source: string;
    countries: number[];
    focus_countries: number[];
    mentioned_countries: number[];
    url: string;
    time: Moment;
    is_analyst?: boolean;
    uscores?: IEventUScore[];
    bullshit_metric: number;
    _score?: number;
    created_at: Moment;
}

export interface ISearchEventsFeedQuery {
    maxDate: Moment;
    limit?: number;
    searchString: string;
    countries?: number[];
    type: 'ALL' | 'SCORED' | 'PENDING';
}

export interface ISearchActivityResult {
    id: string;
    score: number;
}

export interface IEventsByDate {
    [dateStr: string]: IBaseEvent[];
}

export interface IUScoresByEventId {
    [eventId: number]: IEventUScore[];
}

export interface IServerGetEventsResponse {
    scored: {
        events: IEventsByDate;
        uscores: IUScoresByEventId;
    };
    unscored: {
        events: IEventsByDate;
    };
}

export type IServerGetEventsSearchResponse = IServerGetEventsResponse['scored'];

export interface IEvent extends IBaseEvent {
    suggestions?: IEventSuggestion[];
    relevance?: Array<{
        dimension_id: number;
        relevance_value: number;
    }>;
    article_text: string;
}

export enum SCORED_STATUS {
    ALL = 0,
    SCORED = 1,
    UNSCORED = 2,
}

export interface IGetEventsQuery {
    maxDate: string;
    feedType?: EVENTS_FILTER;
    numDaysToFetch?: number;
}

export interface ILoginParams {
    username: string;
    password: string;
}

export enum API_CACHE_KEYS {
    TOKEN = '__gq__T',
    IMPERSONATE = '__gG_IMPERSONATE',
}

export interface IAPIGQChartDataRow {
    date: Moment;
    value: number;
    uscores: number;
}

export interface IUscore {
    country_id: number;
    dimension_id: number;
    impact: number;
    duration: number;
    score_id: string;
    event_id: number;
}

export interface IUscoreForCountry {
    mr: number;
    e: number[];
}
export interface IUscoreForDimension {
    [countryID: number]: IUscoreForCountry;
}

export interface IAPIUscore {
    [dateString: string]: {
        [dimensionID: number]: IUscoreForDimension;
    };
}
export interface IAPIUscoreResponse {
    uscores: IAPIUscore;
    headline_map: {
        [eventId: string]: IEvent;
    };
}

export interface ITimeRange {
    from: Moment;
    until: Moment;
}
export enum E_RISK_TYPE {
    DIMENSIONS = 0,
    CLIENT_FACING_INDICATORS = 1,
    CONTINGENT = 2,
}

export enum RISK_CHART_MODES {
    RISK = 'RISK',
    PULSE = 'PULSE',
    RISK_AND_PULSE = 'RISK_AND_PULSE',
}

export type GLOBAL_INDEXES =
    | 'oil_production_risk'
    | 'oil_price_risk'
    | 'oil_price_normalized';

export interface IEventSuggestion {
    suggested_duration: number;
    dimension_id: number;
    country_id: number;
    suggested_value: number;
}

export enum E_COMPONENT_TYPE {
    MAP = 'map',
    CHART = 'chart',
    RISK = 'risk',
    DELTA = 'delta',
    CUSTOM = 'custom',
    IFRAME = 'iframe',
    CONTINGENT_MAP = 'contingent_map',
    HEATMAP = 'heatmap',
    CONTINGENT_CHART = 'contingent_chart',
}

export enum E_CHART_GROUP_IDENTIFIERS {
    POLITICAL_RISK = 'PoliticalRisk',
    CURRENCY_EXCHANGE = 'CurrencyExchange',
    PROJECTION_LINE = 'ProjectionLine',
    GLOBAL_INDEX = 'GlobalIndex',
    CONTINGENT_RISK = 'ContingentRisk',
    PULSE = 'Pulse',
}

export interface IActivityRecordChartDataSnapshot {
    chartIdentifier: E_CHART_GROUP_IDENTIFIERS;
    country?: number;
    risk?: number;
    riskType?: E_RISK_TYPE;
    date?: Moment;
    dateBegin?: Moment;
    isGQ?: boolean;
    data: number[];
}

export interface IActivitySnapshotKeyValue {
    values: number[];
    isMultiSelect: boolean;
}

export interface IActivitySnapshotCountries extends IActivitySnapshotKeyValue {
    colorsStack: Array<IColorsStackSerializedValuesData<number>>;
}

export interface IActivitySnapshotRisks extends IActivitySnapshotKeyValue {
    colorsStack: {
        dimensionsStack: Array<IColorsStackSerializedValuesData<number>>;
        clientFacingIndicatorsStack: Array<
            IColorsStackSerializedValuesData<number>
        >;
    };
    currencyOn: boolean;
    projectionDataOn: boolean;
    riskChartMode: RISK_CHART_MODES;
    activeGlobalIndicators: GLOBAL_INDEXES[];
}

export interface IActivitySnapshot {
    countries: IActivitySnapshotCountries;
    risks: IActivitySnapshotRisks;
    riskType: E_RISK_TYPE;
    rangeLeft: number;
    rangeRight: number;
    virtualToday: Date;
    baseDate: Date;
    showLines: boolean;
    showCircles: boolean;
    showVirtualToday: boolean;
    activeGroup: number[];
    hasActiveCustomWeight: boolean;
    heatmap?: IActivityHeatmapSnapshot;
}
export interface IActivityHeatmapSnapshot {
    selectedCountry: number;
    countriesList: number[];
    selectedRisk: number;
    startDate: Date;
    timeSpan: E_TIME_PRESET_KEYS;
    activeGroupId: string;
    riskType: E_RISK_TYPE;
    scoreType: HeatmapScoreType;
    selectedPercentile: HeatmapPercentile;
}

export interface INote {
    readonly id: string;
    noteType: E_COMPONENT_TYPE;
    countryID: number;
    text: string;
}

export interface IChartNote extends INote {
    noteType: typeof E_COMPONENT_TYPE.CHART | E_COMPONENT_TYPE.CONTINGENT_CHART;
    date: Date;
    lineType: E_CHART_GROUP_IDENTIFIERS;
    lineIdentifier: string;
    riskID?: number;
    riskType?: E_RISK_TYPE;
}

export interface IMapNote extends INote {
    noteType:
        | typeof E_COMPONENT_TYPE.MAP
        | E_COMPONENT_TYPE.DELTA
        | E_COMPONENT_TYPE.RISK
        | E_COMPONENT_TYPE.CONTINGENT_MAP;
}

export interface IActivityBaseComponent {
    id: string;
    name: string;
    type: E_COMPONENT_TYPE;
    snapshot: IActivitySnapshot;
    disableEdit?: boolean;
    thumbnailURL?: string;
    imageURL?: string;
    csvURL?: string;
    htmlKey?: string;
}

export interface IActivityChartComponent extends IActivityBaseComponent {
    type:
        | typeof E_COMPONENT_TYPE.CHART
        | E_COMPONENT_TYPE.CONTINGENT_CHART
        | E_COMPONENT_TYPE.HEATMAP;
    data?: IActivityRecordChartDataSnapshot[];
    annotations?: IChartNote[];
}

export interface IActivityMapComponent extends IActivityBaseComponent {
    type:
        | typeof E_COMPONENT_TYPE.MAP
        | typeof E_COMPONENT_TYPE.DELTA
        | typeof E_COMPONENT_TYPE.RISK
        | typeof E_COMPONENT_TYPE.CONTINGENT_MAP;
    data?: IActivityRecordChartDataSnapshot[];
    annotations?: IMapNote[];
}

export interface IActivityHeatmapComponent extends IActivityBaseComponent {
    type: typeof E_COMPONENT_TYPE.HEATMAP;
    data?: IActivityRecordChartDataSnapshot[];
    annotations?: INote[];
    scoreType?: HeatmapScoreType;
}
export interface IActivityCustomImageComponent extends IActivityBaseComponent {
    type: E_COMPONENT_TYPE.CUSTOM;
}

export type IActivityComponent =
    | IActivityChartComponent
    | IActivityMapComponent
    | IActivityHeatmapComponent
    | IActivityCustomImageComponent;


export interface AutoInsightMetadata {
    countries_id: number[];
    risks: number[];
    start_date: number;
    end_date: number;
    articles_ids: number[];
    risk_type: E_RISK_TYPE;
    date_created: number;
}
export interface IColorsStackSerializedValuesData<T> {
    key: T;
    color: string;
    isRandom: boolean;
}

export interface IInsightData {
    name: string;
    tags: string[];
    insight: {
        nodes: Descendant[];
    };
}

export enum INSIGHT_FEED_TYPE {
     Geoquant='Geoquant',
     BMI= 'BMI' };

export interface IBasicActivityRecord {
    _id?: string;
    date: Date;
    shareDate?: Date;
    name: string;
    risks: Array<{ type: E_RISK_TYPE; id: number }>;
    countries: number[];
    shared: boolean;
    createdByMe: boolean;
    canEdit?: boolean;
    seenByUser: boolean;
    feedTypes?: INSIGHT_FEED_TYPE[];
}

export interface IActivityRecord {
    _id?: string;
    date: Date;
    insightsData: IInsightData;
    components: IActivityComponent[];
    zipKey?: string;
    shareable?: boolean;
    shared?: boolean;
    createdByMe?: boolean;
    seenByUser?: boolean;
    pdfURL?: string;
}

export type IActivityHistoryURL = string;

export interface IRisk {
    name: string;
    delta: number;
}

export interface ICurrencyExchange {
    [countryId: string]: {
        [date: string]: number;
    };
}

export interface IAPIContingentRisk {
    b: Moment;
    risks: {
        [countryId: number]: {
            [dateDiff: number]: [number, number];
        };
    };
}

export interface IContingentRisk {
    [countryId: number]: number[];
}

export interface IActiveClientFacingIndicator {
    id: number;
    name: string;
    display_name: string;
    abbreviation: string;
    composing_dimensions: [{ id: number }];
    order: number;
    disabled?: boolean;
    definition?: string;
    top_divider?: boolean;
}

export interface IAPIActiveClientFacingIndicator {
    [id: number]: IActiveClientFacingIndicator;
}

export interface IActiveContingentRisk {
    id: number;
    name: string;
}

export interface IGetScoreDeltaQueryParams {
    isScorelessMode?: boolean;
    identifier: number;
    isClientFacingIndicator: boolean;
    activeGroupId?: string;
    virtualToday: Moment;
    dateStart: Moment;
    dateEnd: Moment;
}

export interface IActiveGroup {
    id: string;
    isGQGroup: boolean;
    name: string;
    countries: number[];
}

export interface IActiveGroupsAPIResponse {
    groups: IActiveGroup[];
    active_group: string;
}
export interface ICustomWeightTree {
    dimensionId: number;
    weight?: number;
    children?: { [dimensionId: number]: ICustomWeightTree };
    tier: number;
    path?: number[];
}

export interface IUserCustomWeights {
    [name: string]: ICustomWeightTree;
}

export interface IPresetSub {
    id: string;
    name: string;
    tree: ICustomWeightTree;
    countries: number[];
}
export interface IPreset {
    id: string;
    name: string;
    isActive: boolean;
    editable: boolean;
    tree: ICustomWeightTree;
    subs: IPresetSub[];
}

export interface IGetTopRisksQuery {
    dateStr: string;
    take?: number;
    gq: boolean;
}

export interface IGetPredictionsFeedQuery {
    dateStr?: string;
    take?: number;
    gq: boolean;
}

export interface IGetTopRisksResponse {
    riskId: number;
    country: number;
    delta: number;
}
export interface IGetTopPredictionsResponse {
    correct: number;
    predictions: IGetPredictionsFeed[];
}

export interface IGetTopInsightsResponse {
    countries: number[];
    id: string;
    name: string;
}
export interface IGetTopInsightsFeed {
    countries: number;
    id: string;
    name: string;
}

export interface IPrediction {
    id: number;
    country_id: number;
    abbreviation: string;
    description: string;
    realization_date: string;
    call_date: string;
    confidence: string;
    consensus: string;
    window: string;
    outcome: 'pending' | 'correct' | 'incorrect' | 'partial';
    tags: string;
    insight_refs?: { [id: string]: string };
}
export interface IHeatmapValues {
    [counrtyId: number]: {
        [riskId: number]: number;
    };
}

export interface IWithCountryProps {
    countries?: IActiveCountryMap;
    countriesArr?: IActiveCountry[];
    licensedCountries?: IActiveCountryMap;
    licensedCountriesArr?: IActiveCountry[];
    allCountries?: IActiveCountryMap;
    allCountriesArr?: IActiveCountry[];
}


export interface AutoGeneratedActivityResult {
    promptResponse: string;
}

export type RiskAutoInsight = {
    risk: string;
    text: string;
};

export type AutoInsightResult = {
    prefix: string;
    auto_insights: RiskAutoInsight[];
    articles: number[];
    warnings: string[];
};

export type DeleteEventScoresResponse = {
    uscores: IEventUScore[]
}

export type IUScoreResource = {
    can_score: boolean;
    can_edit: boolean;
    can_delete: boolean;
    is_private?: boolean;
    can_edit_old?:  boolean;
    score_events_feed_by_type?: boolean;
}