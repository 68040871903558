import cx from 'classnames';
import React from 'react';
import { runInAction, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';
import {
    E_COMPONENT_TYPE,
    VIEW,
    E_RISK_TYPE,
    E_CW_STATUS,
    IActivityChartComponent,
    E_CHART_GROUP_IDENTIFIERS,
} from '../../interfaces';
import ChartContainer from '../GQChart/ChartContainer';
import {
    CHART_TOOLBAR_HEIGHT,
    MOMENTJS_DATE_DISPLAY_FORMAT,
} from '../../constants';
import APIService, {
    IActiveCountryMap,
    IActivityHeatmapSnapshot,
    IAPIActiveClientFacingIndicator,
    INote,
} from '../../services/APIService';
import ChartScoresService from '../../services/ChartScoresService';
import rootStore, { IMobxRootState } from '../../RootStore';
import moment from 'moment';
import GQFlag from '../GQFlag';
import GQDimensionChip from '../GQDimensionChip/GQDimensionChip';
import annoStore from '../../stores/AnnotationsStore';
import AnchorList from '../GQActivityPage/Insights/NoteAnchorList';
import ColorStack from '../../utils/ColorStack';
import {
    castComponentToChart,
    componentFactory,
} from '../../stores/SpecificActivityStore';
import {
    ActivityMapComponentStore,
    ActivityChartComponentStore,
    ActivityHeatmapComponentStore,
} from '../../stores/insights/InsightsComponentStore';
import GQMapProvider from '../GQMap/GQMapProvider';
import { globalRiskColor, translateGlobalIndex } from '../../utils/renderUtils';
import LogoImage from 'assets/general/Geoquant_Logo_Digital_White.svg';
import { find, get, includes, isEmpty } from 'lodash/fp';
import { HeatmapProvider } from 'components/Heatmap/HeatmapProvider';

interface State {
    width: number;
    height: number;
    component: ActivityMapComponentStore | ActivityChartComponentStore | ActivityHeatmapComponentStore;
}

type Props = {
    countries?: IActiveCountryMap;
    allCountries?: IActiveCountryMap;
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    id: string;
};

const COMPONENTS_WITH_DATE_RANGE = [
    E_COMPONENT_TYPE.MAP,
    E_COMPONENT_TYPE.CHART,
    E_COMPONENT_TYPE.DELTA,
    E_COMPONENT_TYPE.CONTINGENT_CHART,
];

@inject(({ countryStore, risksStore }: IMobxRootState) => {
    return {
        countries: countryStore.countries.countries,
        allCountries: countryStore.countries.allCountries,
        activeClientFacingIndicators: risksStore.clientFacingIndicators,
    };
})
@observer
export default class GQTemplate extends React.Component<Props, State> {
    private containerRef: HTMLDivElement;
    constructor(props: Props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            component: null,
        };
    }
    public componentDidMount() {
        setTimeout(() => {
            this.getComponent();
        });
        this.adjustComponentSize(); //todo: make sure this is actually needed
    }

    public render() {
        return (
            <div className="app">
                <div
                    className={cx([
                        'gq-template-component',
                        get(['component', 'type'], this.state),
                    ])}>
                    <div className="gq-temlpate-component-header flex-container flex-dir-column">
                        <div className="gq-temlpate-component-header-details">
                            {this.renderHeaderTable()}
                        </div>
                    </div>
                    <div
                        className="gq-template-component-container flex-container flex-child-grow"
                        ref={el => (this.containerRef = el)}>
                        {this.renderComponent()}
                    </div>
                    <div className="gq-template-component-notes flex-container flex-dir-column">
                        {this.getNotes()}
                    </div>
                </div>
            </div>
        );
    }
    private renderComponent() {
        if (!this.state.component) {
            return null;
        }
        const { type } = this.state.component;
        switch (type) {
            case E_COMPONENT_TYPE.CHART:
            case E_COMPONENT_TYPE.CONTINGENT_CHART:
                return this.renderChart();
            case E_COMPONENT_TYPE.DELTA:
            case E_COMPONENT_TYPE.MAP:
            case E_COMPONENT_TYPE.RISK:
            case E_COMPONENT_TYPE.CONTINGENT_MAP:
                return this.renderMap(type);
            case E_COMPONENT_TYPE.HEATMAP:
                return this.renderHeatmap();
        }
        return null;
    }

    private renderMap(type: E_COMPONENT_TYPE) {
        const overrideCountriesList =
            this.state.component && this.state.component.snapshot.activeGroup
                ? new Set(this.state.component.snapshot.activeGroup)
                : null;
        return (
            <GQMapProvider
                view={this.componentTypeToView(type)}
                annotations={this.state.component.annotations}
                overrideCountriesList={overrideCountriesList}
            />
        );
    }

    private renderHeatmap() {
        return <HeatmapProvider disableActiveGroup disableToggle />;
    }

    private get isCountryMultiSelect() {
        const { isMultiSelect } = this.state.component.snapshot.countries;
        return isMultiSelect;
    }

    private get isMultiDims() {
        return !this.isCountryMultiSelect;
    }

    private componentTypeToView = (type: E_COMPONENT_TYPE) => {
        switch (type) {
            case E_COMPONENT_TYPE.MAP:
                return VIEW.RISK_VIEW;
            case E_COMPONENT_TYPE.RISK:
                return VIEW.SORT_BY_RISK;
            case E_COMPONENT_TYPE.DELTA:
                return VIEW.SORT_BT_DELTA;
            case E_COMPONENT_TYPE.CONTINGENT_MAP:
                return VIEW.CONTINGENT;
            case E_COMPONENT_TYPE.CONTINGENT_CHART:
                return VIEW.CONTINGENT_GRAPH;
        }
        return VIEW.RISK_VIEW;
    };
    private renderChart() {
        const componentChartStore = castComponentToChart(this.state.component);
        if (componentChartStore === null) {
            return null;
        }
        //note: this is currently the only way to know which chart type (country/world) it is.
        // eventually this data should be stored inside the snapshot
        const activeView =
            this.state.component.snapshot.riskType === E_RISK_TYPE.CONTINGENT
                ? VIEW.CONTINGENT_GRAPH
                : this.state.component.snapshot.risks.isMultiSelect &&
                  !this.state.component.snapshot.countries.isMultiSelect
                ? VIEW.COUNTRY_GRAPH
                : VIEW.WORLD_GRAPH;
        return [
            <ChartContainer
                activeView={activeView}
                key="template-chart"
                height={480}
                expanded
                padding={{ left: 42, right: 42, top: 0, bottom: 0 }}
                showSlider={false}
                showToolbar={false}
                showVirtualToday={false}
                changeDataWhenDragStop={false}
            />,
            <AnchorList
                key="template-anchor-list"
                container={this.containerRef}
                annotations={componentChartStore.annotations}
                scaleStore={annoStore}
            />,
        ];
    }

    private adjustComponentSize() {
        if (!this.state.component) {
            return;
        }
        const { type } = this.state.component;
        if (this.containerRef) {
            const box = this.containerRef.getBoundingClientRect();
            this.setState({
                width: box.width,
                height:
                    type === E_COMPONENT_TYPE.CHART
                        ? box.height - CHART_TOOLBAR_HEIGHT
                        : box.height,
            });
        }
    }

    private getComponent = async () => {
        const { id } = this.props;
        if (!id) {
            return null;
        }
        const comp = await APIService.getTempComponent(id);
        this.setState(
            {
                component: componentFactory(comp) as any,
            },
            () => {
                runInAction(() => {
                    if (
                        comp.type === E_COMPONENT_TYPE.HEATMAP &&
                        !isEmpty(comp.snapshot.heatmap)
                    ) {
                        const heatmap = comp.snapshot.heatmap;
                        rootStore.heatmapStore.setSelectedCountry(
                            heatmap.selectedCountry
                        );
                        rootStore.heatmapStore.setSelectedRisk(
                            heatmap.selectedRisk
                        );
                        rootStore.heatmapStore.setRiskType(heatmap.riskType);
                        rootStore.heatmapStore.setStartDate(heatmap.startDate);
                        rootStore.heatmapStore.setTimeSpan(heatmap.timeSpan);
                        rootStore.heatmapStore.setScoreType(heatmap.scoreType);
                        rootStore.settingsStore.setActiveGroupId(
                            heatmap.activeGroupId
                        );
                        rootStore.heatmapStore.setCountriesList(
                            heatmap.countriesList
                        );
                        rootStore.heatmapStore.setSelectedPercentile(
                            heatmap.selectedPercentile
                        );
                        return;
                    }

                    const { chartStore } = rootStore;
                    chartStore.baseDate = moment(comp.snapshot.baseDate);
                    chartStore.virtualToday = moment(
                        comp.snapshot.virtualToday
                    );
                    chartStore.rangeLeft = comp.snapshot.rangeLeft;
                    chartStore.rangeRight = comp.snapshot.rangeRight;
                    chartStore.showCircles = comp.snapshot.showCircles;
                    chartStore.showLines = comp.snapshot.showLines;
                    if (comp.snapshot.showVirtualToday !== undefined) {
                        chartStore.showVirtualToday =
                            comp.snapshot.showVirtualToday;
                    }
                    rootStore.risksStore.setCurrentRiskType(
                        comp.snapshot.riskType
                    );

                    rootStore.risksStore.currentStore.overrideValues(
                        ...comp.snapshot.risks.values
                    );
                    rootStore.risksStore.setStacks(
                        ColorStack.fromSerialize(
                            comp.snapshot.risks.colorsStack.dimensionsStack
                        ),
                        ColorStack.fromSerialize(
                            comp.snapshot.risks.colorsStack
                                .clientFacingIndicatorsStack
                        )
                    );
                    rootStore.countryStore.setStacks(
                        ColorStack.fromSerialize(
                            comp.snapshot.countries.colorsStack
                        )
                    );
                    rootStore.countryStore.currentStore.overrideValues(
                        ...comp.snapshot.countries.values
                    );
                    if (
                        comp.type === E_COMPONENT_TYPE.CHART ||
                        comp.type === E_COMPONENT_TYPE.CONTINGENT_CHART
                    ) {
                        const currencyCountries = (comp as IActivityChartComponent).data
                            .filter(
                                d =>
                                    d.chartIdentifier ===
                                    E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE
                            )
                            .map(d => d.country);
                        rootStore.risksStore.setCurrencyCountries(
                            currencyCountries
                        );
                    }
                    rootStore.risksStore.setState({
                        projectionDataOn:
                            comp.snapshot.risks.projectionDataOn !== undefined
                                ? comp.snapshot.risks.projectionDataOn
                                : rootStore.risksStore.projectionDataOn,
                        riskChartMode:
                            comp.snapshot.risks.riskChartMode !== undefined
                                ? comp.snapshot.risks.riskChartMode
                                : rootStore.risksStore.riskChartMode,
                    });
                    rootStore.risksStore.overrideGlobalIndicators(
                        comp.snapshot.risks.activeGlobalIndicators || []
                    );
                    if (
                        comp.snapshot.hasActiveCustomWeight !== undefined &&
                        comp.snapshot.hasActiveCustomWeight
                    ) {
                        rootStore.customWeightStore.updateActivePreset({
                            status: E_CW_STATUS.READY,
                            activePreset: '',
                            activePresetId: null,
                        });
                    }
                    ChartScoresService.setOverrideData(
                        this.state.component.data
                    );
                    if (comp.snapshot.activeGroup) {
                        rootStore.settingsStore.setOverrideActiveCountries(
                            comp.snapshot.activeGroup
                        );
                    }
                });
            }
        );
    };

    private renderHeatmapHeaderTable() {
        const heatmap: IActivityHeatmapSnapshot = get(
            'component.snapshot.heatmap',
            this.state
        );
        if (isEmpty(heatmap)) {
            return null;
        }

        const dateStr = `${moment(heatmap.startDate).format(
            MOMENTJS_DATE_DISPLAY_FORMAT
        )} (${heatmap.timeSpan})`;

        return (
            <table>
                <tbody>
                    <tr>
                        <td>Date</td>
                        <td
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <div>{dateStr}</div>
                            <Logo />
                        </td>
                    </tr>
                    <tr>
                        <td>Score Type</td>
                        <td>{heatmap.scoreType}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    private renderHeaderTable() {
        if (!this.state.component) {
            return;
        }

        if (this.state.component.type === E_COMPONENT_TYPE.HEATMAP) {
            return this.renderHeatmapHeaderTable();
        }
        const countryList = this.props.countries;

        const isContingent = includes(this.state.component.type, [
            E_COMPONENT_TYPE.CONTINGENT_MAP,
            E_COMPONENT_TYPE.CONTINGENT_CHART,
        ]);
        const isContingentMap =
            this.state.component.type === E_COMPONENT_TYPE.CONTINGENT_MAP;

        // if is country mode, don't color me, else, color me
        const countries = this.state.component.snapshot.countries.values.map(
            (c, i) => {
                const countryStackItem = _.find(
                    this.state.component.snapshot.countries.colorsStack,
                    stackItem => stackItem.key === c
                );
                const isPrimaryContingent = i === 0 && isContingent;
                return (
                    <span
                        className="gq-temlpate-country-row"
                        style={{ display: 'inline-block' }}
                        key={`td-country-${c}`}>
                        {this.isMultiDims || this.isSingleMode() ? (
                            [
                                <GQFlag
                                    className="flag-icon-squared"
                                    countryId={c}
                                    key="row-country-flag"
                                />,
                                <span
                                    className="gq-template-country-row-name"
                                    key="row-country-name">
                                    {countryList[c].name}
                                </span>,
                            ]
                        ) : (
                            <span
                                style={{
                                    display: 'inline-block',
                                    fontSize: 13,
                                    textTransform: isPrimaryContingent
                                        ? 'uppercase'
                                        : 'none',
                                    margin: 0,
                                    padding: isPrimaryContingent
                                        ? '0 4px'
                                        : '0 2px',
                                    borderRadius: 3,
                                    borderBottom:
                                        isContingentMap || isPrimaryContingent
                                            ? 'none'
                                            : `2px solid ${get(
                                                  'color',
                                                  countryStackItem
                                              ) || 'transparent'}`,
                                    backgroundColor: isPrimaryContingent
                                        ? 'white'
                                        : 'inherit',
                                    color: isPrimaryContingent
                                        ? '#001328'
                                        : 'inherit',
                                }}>
                                {countryList[c].name}
                            </span>
                        )}
                    </span>
                );
            }
        );
        // if is country mode, color me, else, dont color me
        const countryFirst = _.first(
            this.state.component.snapshot.countries.values
        );
        const dimensions = this.state.component.snapshot.risks.values.map(d => {
            // const countryStackItem = _.find(this.state.component.snapshot.countries.colorsStack, (stackItem) => stackItem.key === countryFirst);
            const riskStackItem = _.find(
                this.getRiskColorStack(),
                item => item.key === d
            );
            const dimStyle: React.CSSProperties = {
                background: 'none',
                borderColor: this.isMultiDims
                    ? riskStackItem
                        ? riskStackItem.color
                        : ''
                    : this.isSingleMode()
                    ? get(
                          'color',
                          find(
                              stackItem => stackItem.key === countryFirst,
                              this.state.component.snapshot.countries
                                  .colorsStack
                          )
                      )
                    : 'transparent',
                padding: 0,
                color: 'white',
                fontSize: 13,
            };

            return (
                <span key={d}>
                    <GQDimensionChip
                        id={d}
                        style={{ ...dimStyle }}
                        isCFI={
                            this.state.component.snapshot.riskType ===
                            E_RISK_TYPE.CLIENT_FACING_INDICATORS
                        }
                    />
                </span>
            );
        });
        const globalIndicatorsSnapshot = toJS(
            this.state.component.snapshot.risks.activeGlobalIndicators
        );
        const globalIndicators = (globalIndicatorsSnapshot || []).map(c => {
            const indicatorStyle: React.CSSProperties = {
                borderBottom: '1.5px dashed transparent',
                borderColor: globalRiskColor(c),
            };

            return (
                <span style={{ ...indicatorStyle }} key={c}>
                    {translateGlobalIndex[c]}
                </span>
            );
        });

        const currencies = this.currencyCountriesId.map(curCountryId => {
            const countryStackItem = _.find(
                this.state.component.snapshot.countries.colorsStack,
                stackItem => stackItem.key === curCountryId
            );
            // const riskStackItem = _.find(this.getRiskColorStack(), (item) => item.key === dimFirst);
            const currencyStyle: React.CSSProperties = {
                padding: '0 4px',
                borderBottom: '1px dashed transparent',
                borderColor: countryStackItem.color,
            };
            const currencyCode = this.props.allCountries[curCountryId]
                .currency_code;
            const currencyExchangeStr =
                currencyCode === 'USD' ? 'EUR - USD' : `USD - ${currencyCode}`;
            return (
                <span
                    style={{ ...currencyStyle }}
                    key={`currency_${curCountryId}`}>
                    {currencyExchangeStr}
                </span>
            );
        });
        const {
            baseDate,
            virtualToday,
            rangeLeft,
            rangeRight,
        } = this.state.component.snapshot;
        const dateStr = includes(
            this.state.component.type,
            COMPONENTS_WITH_DATE_RANGE
        )
            ? `${moment(baseDate)
                  .subtract(rangeLeft, 'd')
                  .format(MOMENTJS_DATE_DISPLAY_FORMAT)} - ${moment(baseDate)
                  .add(rangeRight, 'd')
                  .format(MOMENTJS_DATE_DISPLAY_FORMAT)}`
            : moment(virtualToday).format(MOMENTJS_DATE_DISPLAY_FORMAT);
        const dateLabelStr = includes(
            this.state.component.type,
            COMPONENTS_WITH_DATE_RANGE
        ) ? 'Dates': 'Date';
        return (
            <table>
                <tbody>
                    {((this.state.component.snapshot.risks.values.length &&
                        this.state.component.type !== E_COMPONENT_TYPE.CHART) ||
                        (this.state.component.snapshot.countries.values.length >
                            0 &&
                            this.state.component.type ===
                                E_COMPONENT_TYPE.CHART)) && (
                        <tr>
                            <td>{this.risksHeader}</td>
                            <td
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <div>{dimensions}</div>
                                <Logo />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>{dateLabelStr}</td>
                        <td>{dateStr}</td>
                    </tr>
                    {this.state.component.type === E_COMPONENT_TYPE.MAP && (
                        <tr>
                            <td>Virtual Today</td>
                            <td>{moment(virtualToday).format(MOMENTJS_DATE_DISPLAY_FORMAT)}</td>
                        </tr>
                    )}
                    {this.state.component.snapshot.countries.values.length >
                        0 && (
                        <tr>
                            <td>{this.getCountryHeader()}</td>
                            <td>{countries}</td>
                        </tr>
                    )}
                    {Array.isArray(globalIndicatorsSnapshot) &&
                    globalIndicatorsSnapshot.length > 0 ? (
                        <tr>
                            <td>
                                {globalIndicatorsSnapshot.length === 1
                                    ? 'Index'
                                    : 'Indices'}
                            </td>
                            <td>{globalIndicators}</td>
                        </tr>
                    ) : null}
                    {this.state.component.snapshot.risks.currencyOn &&
                        this.state.component.snapshot.countries.values.length >
                            0 && (
                            <tr>
                                <td>{this.currencyHeader}</td>
                                <td>{currencies}</td>
                            </tr>
                        )}
                </tbody>
            </table>
        );
    }

    private getCountryHeader() {
        return this.state.component.snapshot.countries.values.length > 1
            ? 'Countries'
            : 'Country';
    }

    private isSingleMode() {
        return (
            this.state.component.snapshot.countries.values.length === 1 &&
            this.state.component.snapshot.risks.values.length === 1
        );
    }

    private getRiskColorStack() {
        if (this.state.component.snapshot.riskType === E_RISK_TYPE.DIMENSIONS) {
            return this.state.component.snapshot.risks.colorsStack
                .dimensionsStack;
        } else {
            return this.state.component.snapshot.risks.colorsStack
                .clientFacingIndicatorsStack;
        }
    }

    private getNotes() {
        if (this.state.component) {
            return this.renderNotes(this.state.component.annotations);
        } else {
            return null;
        }
    }

    private renderNotes(notes: INote[]) {
        return notes.map((annotation, i) => (
            <div
                className="gq-temlpate-component-notes-row flex-container align-middle"
                key={annotation.id}>
                <div className="gq-temlpate-component-notes-row-index flex-container align-center-middle">
                    {i + 1}
                </div>
                <div className="gq-temlpate-component-notes-row-text">
                    <p>{annotation.text}</p>
                </div>
            </div>
        ));
    }
    private get risksHeader() {
        return this.state.component.snapshot.risks.values.length > 1
            ? 'Risks'
            : 'Risk';
    }

    private get currencyHeader() {
        return this.currencyCountriesId.length > 1 ? 'Currencies' : 'Currency';
    }

    private get currencyCountriesId(): number[] {
        const snapshotData = _.get(this.state, 'component.data', []);
        return snapshotData
            .filter(
                d =>
                    d.chartIdentifier ===
                    E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE
            )
            .map(d => d.country);
    }
}

const Logo = React.memo(() => (
    <div className="gq-template-component-watermark">
        <img src={LogoImage} alt=""/>
    </div>
));