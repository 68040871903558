import React from 'react';
import * as _ from 'lodash';
import { find, get, includes, isEmpty } from 'lodash/fp';
import moment from 'moment';
import ActivityView from './ActivityView';
import { RouteComponentProps, withRouter } from 'react-router';
import rootStore, { IMobxRootState } from '../../../RootStore';
import APIService, {
    IActivityRecord,
    INSIGHT_FEED_TYPE,
    IPrediction,
} from '../../../services/APIService';
import ActivityPageTable from './ActivityPageTable';
import EventsService, {
    GQEmitterEvents,
} from '../../../services/EventsService';
import CancelableEvents from '../../../utils/CancelableEvents';
import { E_TAB_FILTER, E_TIME_FILTER } from './ActivityPageInterfaces';
import { IMapById, IBasicActivityRecord, VIEW } from '../../../interfaces';
import { arrayToMapObjectById } from '../../../utils/generalUtils';
import { GQToastContainer } from '../../GQToast/GQToastContainer';
import {
    C_ACITIVTY_DELETED,
    C_ACTIVITY_PUBLISHED,
    C_ACTIVITY_UNPUBLISHED,
    C_NO_PERMISSIONS,
} from '../../../constants';
import { E_TOAST_TYPE } from '../../GQToast/GQToast';
import { GQButton } from '../../GQButton';
import loading from '../../../services/LoadingService';
import { inject, observer } from 'mobx-react';

export enum E_DOWNLOAD_TRIGGER {
    image = 'img',
    csv = 'csv',
    zip = 'zip',
}

export interface IActiveIDMapping {
    [E_TAB_FILTER.GQ]: string;
    [E_TAB_FILTER.MY]: string;
    [E_TAB_FILTER.BMI]: string;
    [E_TAB_FILTER.PREDICTIONS]: string;
}
interface InsightsState {
    predictions?: IMapById<IPrediction>;
    insights: IMapById<IBasicActivityRecord>;
    timeFilter: E_TIME_FILTER;
    tabFilter: E_TAB_FILTER;
    tabToIdMapping: IActiveIDMapping;
    trashCan: Set<string>;
    searchVal: string;
    showSearch: boolean;
    activeItem: IActivityRecord | IPrediction;
    loadingData: boolean;
    searchFilter: Set<string> | null;
    feedTypes?: INSIGHT_FEED_TYPE[];
}

interface InsightsProps extends RouteComponentProps<{ id: string }> {
    canShareInsights?: boolean;
    canDownloadInsightData?: boolean;
}

@inject(({ UserStore }: IMobxRootState) => {
    return {
        canShareInsights: UserStore.geoquantFlagsPermission.can_share_insights,
        canDownloadInsightData: UserStore.geoquantFlagsPermission.can_download_insights_data,
    };
})
@observer
class Insights extends React.Component<InsightsProps, InsightsState> {
    private searchDebounce: _.Cancelable & Function;
    private cancelableEvents = new CancelableEvents();
    private toastContainerRef: GQToastContainer;
    constructor(props: any) {
        super(props);
        this.state = {
            predictions: null,
            insights: null,
            timeFilter: E_TIME_FILTER.ALL,
            tabFilter: E_TAB_FILTER.GQ,
            tabToIdMapping: {
                [E_TAB_FILTER.GQ]: null,
                [E_TAB_FILTER.MY]: null,
                [E_TAB_FILTER.BMI]: null,
                [E_TAB_FILTER.PREDICTIONS]: null,
            },
            trashCan: new Set(),
            showSearch: false,
            searchVal: '',
            activeItem: null,
            loadingData: true,
            searchFilter: null,
        };
        this.searchDebounce = _.debounce(this.performActualSearch, 150);
    }
    public componentDidMount() {
        this.loadData(true);
        rootStore.appStore.setCurrentDisplayView(VIEW.INSIGHTS);
        this.cancelableEvents.addEventSubscription(
            EventsService.registerEvent(
                GQEmitterEvents.PN_NEW_GQ_INSIGHT,
                this.loadData.bind(this, false)
            )
        );
    }

    public componentWillUnmount() {
        this.cancelableEvents.cancelAll();
    }

    public componentDidUpdate(
        prevProps: InsightsProps,
        prevState: InsightsState
    ) {
        if (this.shouldCareAboutComponentDidUpdate(prevProps, prevState)) {
            const newState: Partial<InsightsState> = {};
            if (this.activeId in this.state.predictions) {
                if (this.state.tabFilter !== E_TAB_FILTER.PREDICTIONS) {
                    newState.tabFilter = E_TAB_FILTER.PREDICTIONS;
                    newState.tabToIdMapping[E_TAB_FILTER.PREDICTIONS] = String(
                        this.activeId
                    );
                }

                if (
                    !this.shouldShowPrediction(
                        this.state.predictions[this.activeId]
                    )
                ) {
                    newState.timeFilter = E_TIME_FILTER.ALL;
                }
            }

            if (this.activeId in this.state.insights) {
                if (this.state.tabFilter === E_TAB_FILTER.PREDICTIONS) {
                    newState.tabFilter = this.state.insights[this.activeId]
                        .createdByMe
                        ? E_TAB_FILTER.MY
                        : E_TAB_FILTER.GQ;
                    newState.tabToIdMapping = {
                        ...this.state.tabToIdMapping,
                        [newState.tabFilter]: this.activeId,
                    };
                }

                if (
                    !this.shouldShowInsight(this.state.insights[this.activeId])
                ) {
                    newState.timeFilter = E_TIME_FILTER.ALL;
                }
                this.loadInsight();
            }

            this.setState(newState as InsightsState);
        }

        if (
            prevState.tabFilter !== this.state.tabFilter &&
            (prevState.tabFilter === E_TAB_FILTER.PREDICTIONS ||
                this.state.tabFilter === E_TAB_FILTER.PREDICTIONS) &&
            this.state.searchVal.length > 0
        ) {
            this.onSearchChange(this.state.searchVal);
        }
    }

    public render() {
        return (
            <div className="activity-history-container flex-container">
                <div className="flex-container align-right activity-history-cell">
                    <ActivityPageTable
                        predictions={this.getFilteredPredictions()}
                        activities={this.getFilteredInsights()}
                        onActivitySelect={this.onActivitySelected}
                        onActivityDelete={this.deleteLocalActivity}
                        onActivityBroadcast={this.shareActivity}
                        onActivityDownload={this.triggerDownload}
                        onActivityEdit={this.onActivityEdit}
                        onPredictionClick={this.setActivePrediction}
                        onTabChange={this.onTabChange}
                        activeTab={this.state.tabFilter}
                        onTimeFilterChange={this.onTimeFilterChange}
                        timeFilter={this.state.timeFilter}
                        activePredictionId={this.getActivePredictionID()}
                        activeInsightId={this.getActiveInsightID()}
                        showSearch={this.state.showSearch}
                        onSearchChange={this.onSearchChange}
                        toggleSearch={this.toggleSearchBox}
                        clearSearch={this.clearSearchBox}
                        searchVal={this.state.searchVal}
                        canShareInsights={this.props.canShareInsights}
                        canDownloadInsightData={this.props.canDownloadInsightData}
                    />
                </div>
                <div className="activity-cell-separator" />
                <div className="flex-container activity-history-cell activity-content">
                    <ActivityView
                        activeItem={this.state.activeItem}
                        loading={this.state.loadingData}
                    />
                </div>
                <GQToastContainer ref={el => (this.toastContainerRef = el)} />
            </div>
        );
    }

    private onSearchChange = (
        e: React.ChangeEvent<HTMLInputElement> | string
    ) => {
        const searchVal = typeof e === 'string' ? e : e.currentTarget.value;
        this.setState(
            {
                searchVal,
                searchFilter:
                    searchVal.length > 0 ? this.state.searchFilter : null,
            },
            () => {
                this.searchDebounce.cancel();
                if (this.state.searchVal.length) {
                    this.searchDebounce();
                }
            }
        );
    };

    private toggleSearchBox = () => {
        this.setState(currentState => {
            const showSearch = !currentState.showSearch;
            return {
                showSearch,
                searchFilter: showSearch ? null : currentState.searchFilter,
            };
        });
    };

    private clearSearchBox = () => {
        this.setState({
            searchVal: '',
            searchFilter: null,
        });
    };

    private performActualSearch = async () => {
        try {
            const isPredictionsTab =
                this.state.tabFilter === E_TAB_FILTER.PREDICTIONS;
            const searchString = this.state.searchVal;
            const searchResult = await this.cancelableEvents.promise(() =>
                APIService.searchActivities(
                    isPredictionsTab ? 'predictions' : 'all',
                    this.state.searchVal
                )
            );
            if (
                isPredictionsTab !==
                    (this.state.tabFilter === E_TAB_FILTER.PREDICTIONS) ||
                searchString !== this.state.searchVal
            ) {
                return;
            }
            this.setState({
                searchFilter: new Set(searchResult.map(({ id }) => id)),
            });
        } catch (err) {
            throw err;
        }
    };

    private getActivePredictionID() {
        if (
            this.state.predictions &&
            this.state.tabFilter === E_TAB_FILTER.PREDICTIONS &&
            this.activeId &&
            this.state.predictions[this.activeId]
        ) {
            return this.state.predictions[this.activeId].id;
        }

        return null;
    }

    private onURLChange(id: string) {
        const fullUrl = `/${VIEW.INSIGHTS}/${id}`;
        if (this.props.location.pathname === fullUrl) {
            return;
        }
        this.props.history.push(fullUrl);
    }

    private getActiveInsightID() {
        if (
            this.state.insights &&
            this.state.tabFilter !== E_TAB_FILTER.PREDICTIONS &&
            this.activeId &&
            this.state.insights[this.activeId]
        ) {
            return this.state.insights[this.activeId]._id;
        }

        return null;
    }

    private onTimeFilterChange = (timeFilter: E_TIME_FILTER) => {
        this.setState(
            this.onTimeFilterChangeCalculateNextState(
                timeFilter
            ) as InsightsState,
            () => {
                this.onURLChange(
                    this.state.tabToIdMapping[this.state.tabFilter] || ''
                );
            }
        );
    };

    private onTimeFilterChangeCalculateNextState(
        nextTimeFilter: E_TIME_FILTER
    ) {
        const newState: Partial<InsightsState> = {
            tabToIdMapping: {
                [E_TAB_FILTER.GQ]: null,
                [E_TAB_FILTER.MY]: null,
                [E_TAB_FILTER.BMI]: null,
                [E_TAB_FILTER.PREDICTIONS]: null,
            },
            timeFilter: nextTimeFilter,
        };
        if (!this.activeId) {
            return newState;
        }
        switch (this.state.tabFilter) {
            case E_TAB_FILTER.PREDICTIONS:
                const currentPrediction = this.state.predictions[this.activeId];
                if (
                    currentPrediction &&
                    this.shouldShowPrediction(
                        currentPrediction,
                        this.state.tabFilter,
                        nextTimeFilter
                    )
                ) {
                    newState.tabToIdMapping[E_TAB_FILTER.PREDICTIONS] = String(
                        currentPrediction.id
                    );
                } else {
                    const predictions = this.getFilteredPredictions(
                        this.state.tabFilter,
                        nextTimeFilter
                    );
                    if (predictions.length) {
                        newState.tabToIdMapping[
                            E_TAB_FILTER.PREDICTIONS
                        ] = String(predictions[0].id);
                    }
                }
                break;
            default:
                const currentInsight = this.state.insights[this.activeId];
                if (
                    currentInsight &&
                    this.shouldShowInsight(
                        currentInsight,
                        this.state.tabFilter,
                        nextTimeFilter
                    )
                ) {
                    newState.tabToIdMapping[this.state.tabFilter] =
                        currentInsight._id;
                } else {
                    const insights = this.getFilteredInsights(
                        this.state.tabFilter,
                        nextTimeFilter
                    );
                    if (insights.length) {
                        newState.tabToIdMapping[this.state.tabFilter] =
                            insights[0]._id;
                    }
                }
        }

        return newState;
    }

    private onTabChangeCalculateNextState(
        nextTab: E_TAB_FILTER
    ): Partial<InsightsState> {
        const newState: Partial<InsightsState> = {
            tabFilter: nextTab,
            tabToIdMapping: {
                ...this.state.tabToIdMapping,
            },
        };

        if (nextTab === E_TAB_FILTER.PREDICTIONS) {
            let nextPredictionID = this.state.tabToIdMapping[nextTab];
            const predictions = this.getFilteredPredictions(nextTab);
            if (predictions.length) {
                if (nextPredictionID === null) {
                    nextPredictionID = String(predictions[0].id);
                    newState.activeItem = predictions[0];
                } else {
                    newState.activeItem = predictions.find(
                        p => p.id.toString() === nextPredictionID
                    );
                }
            }

            newState.tabToIdMapping[
                E_TAB_FILTER.PREDICTIONS
            ] = nextPredictionID;
            return newState;
        }
        if (!this.state.insights) {
            return;
        }
        if (this.activeId in this.state.insights) {
            const insight = this.state.insights[this.activeId];
            if (this.shouldShowInsight(insight, nextTab)) {
                newState.tabToIdMapping[nextTab] = insight._id;
                return newState;
            }
        }

        let nextId = newState.tabToIdMapping[nextTab];
        if (nextId === null) {
            const nextIds = this.getFilteredInsights(nextTab);
            if (nextIds.length) {
                nextId = nextIds[0]._id;
            }
        }

        newState.tabToIdMapping[nextTab] = nextId;
        return newState;
    }
    private onTabChange = (tabFilter: E_TAB_FILTER) => {
        if (tabFilter === this.state.tabFilter) {
            return;
        }
        this.setState(
            this.onTabChangeCalculateNextState(tabFilter) as InsightsState,
            () => {
                this.onURLChange(this.state.tabToIdMapping[tabFilter] || '');
            }
        );
    };

    private shouldCareAboutComponentDidUpdate(
        prevProps: InsightsProps,
        prevState: InsightsState
    ) {
        return (
            this.state.insights !== null &&
            this.state.predictions !== null &&
            prevProps.match.params.id !== this.activeId &&
            this.activeId !== undefined &&
            prevState.tabFilter === this.state.tabFilter &&
            prevState.timeFilter === this.state.timeFilter
        );
    }

    private setActivePrediction = (id: number) => {
        if (!(id in this.state.predictions)) {
            return;
        }
        this.setState(
            {
                tabFilter: E_TAB_FILTER.PREDICTIONS,
                tabToIdMapping: {
                    ...this.state.tabToIdMapping,
                    [E_TAB_FILTER.PREDICTIONS]: String(id),
                },
                activeItem: this.state.predictions[id],
                loadingData: false,
            },
            () => {
                this.onURLChange(String(id));
            }
        );
    };

    private onActivityEdit = (activity: IBasicActivityRecord) => {
        rootStore.routingStore.push(
            `/${VIEW.CREATE_INSIGHT}/${VIEW.RISK_VIEW}/${activity._id}`
        );
    };

    private handleIdChange(
        newState: Partial<InsightsState>,
        insights: IBasicActivityRecord[],
        predictions: IPrediction[]
    ): string {
        let id = this.activeId;
        if (id) {
            if (id in newState.insights || id in newState.predictions) {
                if (newState.insights[id] !== undefined) {
                    const insight = newState.insights[id];
                    const nextTab = insight.createdByMe
                        ? E_TAB_FILTER.MY
                        : includes(INSIGHT_FEED_TYPE.BMI, insight.feedTypes)
                        ? E_TAB_FILTER.BMI
                        : E_TAB_FILTER.GQ;
                    newState.tabToIdMapping[nextTab] = id;
                    newState.tabFilter = nextTab;
                    this.loadInsight();
                }

                if (newState.predictions[id] !== undefined) {
                    newState.tabToIdMapping[E_TAB_FILTER.PREDICTIONS] = id;
                    newState.tabFilter = E_TAB_FILTER.PREDICTIONS;
                    newState.activeItem = newState.predictions[id];
                    newState.loadingData = false;
                }
            } else {
                id = null;
            }
        }
        if (!id && insights.length) {
            id = get(
                '_id',
                find(
                    insight =>
                        includes(
                            INSIGHT_FEED_TYPE.Geoquant,
                            get('feedTypes', insight)
                        ),
                    insights
                )
            );
            if (id) {
                newState.tabToIdMapping[E_TAB_FILTER.GQ] = id;
                newState.tabFilter = E_TAB_FILTER.GQ;
            }
        }

        return id;
    }
    private loadData = async (isTriggeredByMount: boolean) => {
        try {
            loading.start();
            const [
                insights,
                predictions,
            ] = await this.cancelableEvents.promise(() =>
                Promise.all([
                    APIService.getActivities(),
                    APIService.getPredictions(),
                ])
            );

            const newState: Partial<InsightsState> = {
                insights: arrayToMapObjectById<IBasicActivityRecord>(
                    insights,
                    '_id'
                ),
                predictions: arrayToMapObjectById<IPrediction>(
                    predictions,
                    'id'
                ),
                tabToIdMapping: {
                    ...this.state.tabToIdMapping,
                },
            };
            let nextId: string;
            if (isTriggeredByMount) {
                nextId = this.handleIdChange(newState, insights, predictions);
            }
            this.setState(newState as InsightsState, () => {
                if (isTriggeredByMount) {
                    this.onURLChange(nextId || '');
                }
            });
            loading.stop();
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            console.error(err.message);
        }
    };

    private shouldShowInsight(
        insight: IBasicActivityRecord,
        activeTab: E_TAB_FILTER = this.state.tabFilter,
        activeTime: E_TIME_FILTER = this.state.timeFilter
    ) {
        if (this.state.trashCan.has(insight._id)) {
            return false;
        }

        if (activeTab === E_TAB_FILTER.PREDICTIONS) {
            return false;
        }

        if (
            this.state.searchFilter !== null &&
            !this.state.searchFilter.has(insight._id)
        ) {
            return false;
        }

        if (
            insight.createdByMe &&
            !insight.shared &&
            includes(activeTab, [E_TAB_FILTER.GQ, E_TAB_FILTER.BMI])
        ) {
            return false;
        }

        if (!insight.createdByMe && activeTab === E_TAB_FILTER.MY) {
            return false;
        }

        const feedTypes = !isEmpty(insight.feedTypes)
            ? insight.feedTypes
            : [INSIGHT_FEED_TYPE.Geoquant];
        if (
            activeTab === E_TAB_FILTER.BMI &&
            !includes(INSIGHT_FEED_TYPE.BMI, feedTypes)
        ) {
            return false;
        }
        if (
            activeTab === E_TAB_FILTER.GQ &&
            !includes(INSIGHT_FEED_TYPE.Geoquant, feedTypes)
        ) {
            return false;
        }

        if (activeTime !== null && activeTime !== E_TIME_FILTER.ALL) {
            const convertTimeFilter = () => {
                switch (activeTime) {
                    case E_TIME_FILTER.MONTH:
                        return [1, 'month'];
                    case E_TIME_FILTER.WEEK:
                        return [1, 'week'];
                }
            };
            const time = moment().subtract(...convertTimeFilter());
            return moment(insight.date).isSameOrAfter(time);
        }
        return true;
    }

    private shouldShowPrediction(
        prediction: IPrediction,
        activeTab: E_TAB_FILTER = this.state.tabFilter,
        activeTime: E_TIME_FILTER = this.state.timeFilter
    ) {
        if (activeTab !== E_TAB_FILTER.PREDICTIONS) {
            return false;
        }
        if (
            this.state.searchFilter !== null &&
            !this.state.searchFilter.has(String(prediction.id))
        ) {
            return false;
        }
        if (activeTime !== null && activeTime !== E_TIME_FILTER.ALL) {
            const convertTimeFilter = () => {
                switch (activeTime) {
                    case E_TIME_FILTER.MONTH:
                        return [1, 'month'];
                    case E_TIME_FILTER.WEEK:
                        return [1, 'week'];
                }
            };
            const time = moment().subtract(...convertTimeFilter());
            return moment(prediction.realization_date).isSameOrAfter(time);
        }
        return true;
    }
    private getFilteredInsights(
        activeTab: E_TAB_FILTER = this.state.tabFilter,
        activeTime: E_TIME_FILTER = this.state.timeFilter
    ) {
        return _.values(this.state.insights).filter(i =>
            this.shouldShowInsight(i, activeTab, activeTime)
        );
    }

    private getFilteredPredictions(
        activeTab: E_TAB_FILTER = this.state.tabFilter,
        activeTime: E_TIME_FILTER = this.state.timeFilter
    ): IPrediction[] {
        if (activeTab !== E_TAB_FILTER.PREDICTIONS) {
            return [];
        }

        return _.values(this.state.predictions)
            .filter(prediction =>
                this.shouldShowPrediction(prediction, activeTab, activeTime)
            )
            .sort((a, b) => {
                if (b.call_date !== undefined && a.call_date !== undefined) {
                    return (
                        moment(b.call_date).valueOf() -
                        moment(a.call_date).valueOf()
                    );
                }
                return b.id - a.id;
            });
    }

    private onActivitySelected = (activity: IBasicActivityRecord) => {
        if (
            activity === null ||
            rootStore.routingStore.location.pathname.indexOf(activity._id) > -1
        ) {
            return;
        }
        const shouldReportSeen = !activity.seenByUser && !activity.createdByMe;
        if (shouldReportSeen) {
            APIService.reportSeenInsight(activity._id);
        }

        this.setState({
            insights: shouldReportSeen
                ? {
                      ...this.state.insights,
                      [activity._id]: {
                          ...activity,
                          seenByUser: true,
                      },
                  }
                : this.state.insights,
            tabToIdMapping: {
                ...this.state.tabToIdMapping,
                [this.state.tabFilter]: activity._id,
            },
        });
        this.onURLChange(activity._id);
    };

    private deleteActivity = async (activity: IBasicActivityRecord) => {
        try {
            await this.cancelableEvents.promise(() =>
                APIService.deleteActivity(activity._id)
            );
            const insights = { ...this.state.insights };
            delete insights[activity._id];
            this.setState({
                insights,
            });
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            this.setState(currentState => {
                const t = new Set(currentState.trashCan);
                t.delete(activity._id);
                return {
                    trashCan: t,
                };
            });
            console.error(err);
            throw err;
        }
    };

    private deleteLocalActivity = (activity: IBasicActivityRecord) => {
        const newTrash = new Set(this.state.trashCan);
        newTrash.add(activity._id);
        this.setState(
            {
                trashCan: newTrash,
            },
            () => {
                if (this.toastContainerRef) {
                    const onUndo = () => {
                        this.undoLocalDelete(activity);
                    };
                    this.toastContainerRef.createToast(C_ACITIVTY_DELETED, {
                        type: E_TOAST_TYPE.ERROR,
                        onDurationEnd: () => {
                            this.deleteActivity(activity);
                        },
                        buttons: [
                            <GQButton
                                key="activity_undo_delete"
                                caption="Undo"
                                onClick={onUndo}
                            />,
                        ],
                    });
                } else {
                    console.error('Toast container not available');
                }
            }
        );
    };

    private undoLocalDelete = (activity: IBasicActivityRecord) => {
        const t = new Set(this.state.trashCan);
        t.delete(activity._id);
        this.setState({
            trashCan: t,
        });
    };

    private shareActivity = async (
        activity: IBasicActivityRecord,
        sendEmail: boolean
    ) => {
        try {
            const newActivity = await this.cancelableEvents.promise(() =>
                APIService.shareActivitiy(activity, sendEmail)
            );
            this.setState(
                {
                    insights: {
                        ...this.state.insights,
                        [newActivity._id]: newActivity,
                    },
                },
                this.onShareActivitySetStateDone.bind(this, newActivity)
            );
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            console.error(err);
            throw err;
        }
    };

    private onShareActivitySetStateDone(activity: IBasicActivityRecord) {
        if (this.toastContainerRef) {
            this.toastContainerRef.createToast(
                activity.shared ? C_ACTIVITY_PUBLISHED : C_ACTIVITY_UNPUBLISHED,
                { type: E_TOAST_TYPE.SUCCESS }
            );
        } else {
            console.error('Toast container not available');
        }
    }

    private triggerDownload = (activity: IBasicActivityRecord) => {
        const zipURL = (activity as any).zipURL;
        if (isEmpty(zipURL) && this.toastContainerRef) {
            this.toastContainerRef.createToast(C_NO_PERMISSIONS, { type: E_TOAST_TYPE.ERROR });
        }
        else {
            try {
                // @TODO: use https://github.com/eligrey/FileSaver.js/
                const downloadLink = document.createElement('a');
                downloadLink.href = zipURL;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            } catch (err) {}
        }
    };

    private loadInsight() {
        this.setState({ loadingData: true }, async () => {
            try {
                const id = this.activeId;
                const insight = await this.cancelableEvents.promise(() =>
                    APIService.getFullActivity(id)
                );
                if (!insight) {
                    throw new Error('No activity found');
                }
                if (insight._id !== id) {
                    return;
                }

                this.setState({
                    activeItem: insight,
                    loadingData: false,
                });
            } catch (err) {
                if (CancelableEvents.isCanceledPromiseError(err)) {
                    return;
                }
                console.error(err);
            }
        });
    }

    private get activeId() {
        return this.props.match.params.id;
    }
}

export default withRouter(Insights);
