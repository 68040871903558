import React from 'react';
import cx from 'classnames';
import {
    IActivityBaseComponent,
    E_COMPONENT_TYPE,
} from '../../../services/APIService';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';
import CancelableEvents from '../../../utils/CancelableEvents';
import EventsService from '../../../services/EventsService';
import { GQModal } from '../../GQModal';
export interface IGQEditorImageProps {
    component: IActivityBaseComponent;
    attributes: object;
}

interface IGQEditorImageState {
    showMenu: boolean;
    showEllipsis: boolean;
    showModal: boolean;
}

class GQEditorImage extends React.Component<
    IGQEditorImageProps,
    IGQEditorImageState
> {
    private cancelable: CancelableEvents;
    constructor(props: IGQEditorImageProps) {
        super(props);
        this.cancelable = new CancelableEvents();
        this.state = {
            showMenu: false,
            showEllipsis: false,
            showModal: false,
        };
    }
    public componentDidMount() {
        this.cancelable.addEventSubscription(
            EventsService.registerOnMouseLeftClick(() => {
                GQPopupStorage.addData('insight-editor-image-popup', null);
            })
        );
        this.cancelable.addEventSubscription(
            EventsService.registerOnEscKeyboardClick(() => {
                this.setState({
                    showModal: false,
                });
            })
        );
    }
    public componentWillUnmount() {
        this.cancelable.cancelAll();
        GQPopupStorage.addData('insight-editor-image-popup', null);
    }
    public render() {
        return (
            <div
                className={cx(['gq-insight-editor-img-node'])}
                style={{ position: 'relative' }}
                onMouseEnter={this.onImageHover}
                onMouseLeave={this.onImageHoverOut}
                {...this.props.attributes}
                contentEditable={false}>
                <img
                    alt="Insight Image"
                    src={this.props.component.imageURL}
                    onClick={this.onZoomClick}
                />
                {this.props.children}
                <GQModal show={this.state.showModal} onClick={this.hideModal}>
                    <img
                        alt=""
                        src={this.props.component.imageURL}
                        className="gq-full-image"
                    />
                </GQModal>
            </div>
        );
    }
    private onImageHover = () => {
        if (
            this.props.component &&
            this.props.component.type === E_COMPONENT_TYPE.CUSTOM
        ) {
            return;
        }
        this.setState({
            showEllipsis: true,
        });
    };
    private onImageHoverOut = () => {
        if (
            this.state.showEllipsis &&
            !GQPopupStorage.isExisting('insight-editor-image-popup')
        ) {
            this.setState({
                showEllipsis: false,
            });
        }
    };

    private onZoomClick = (e: React.MouseEvent<HTMLImageElement>) => {
        this.setState({
            showModal: true,
        });
    };
    private hideModal = () => {
        this.setState({
            showModal: false,
        });
    };
}

export default GQEditorImage;
