import * as Ifs from './APIServiceInterfaces';
import * as _ from 'lodash';
import { ICircleData, IDeltaByCountry } from '../../interfaces';
import moment from 'moment';
import scoreDatesHandler from './scoresProxyUtils';
import { isNil } from 'lodash/fp';
const DATE_FORMAT = 'YYYY-MM-DD';
export default class ScoresCalc {
    public static calculateScoreDelta(
        scores: { [countryId: number]: Ifs.IAPIScores },
        scoresDelta: {
            [countryId: number]: Array<Partial<Ifs.IGlobalTopRisksDelta>>;
        },
        countries: Ifs.IActiveCountryMap,
        riskId: number,
        riskType: Ifs.E_RISK_TYPE,
        date: string | moment.Moment | Date
    ): ICircleData[] {
        const countryDeltaForDimension: { [countryId: number]: number } = {};
        _.forEach(scoresDelta, (topRisks, countryId) => {
            const curRisk = topRisks.find(
                risk => risk.riskId === riskId && risk.riskType === riskType
            );
            if (curRisk)
                countryDeltaForDimension[Number(countryId)] = curRisk.delta;
        });
        let maxScore: number = -Infinity;
        let minScore: number = Infinity;
        const maxDelta: number = Math.max(
            ...Object.values(countryDeltaForDimension)
        );
        const minDelta: number = Math.min(
            ...Object.values(countryDeltaForDimension)
        );
        const res: ICircleData[] = [];
        const toDate = moment(date);
        const toDateIndex = toDate.format(DATE_FORMAT);

        _.forEach(countries, (country: Ifs.IActiveCountry) => {
            try {
                const scoreArr = scores[country.id];
                const scoreToday =
                    riskType === Ifs.E_RISK_TYPE.CONTINGENT
                        ? scoreDatesHandler.getContingent(scoreArr, toDateIndex)
                        : scoreDatesHandler.get(scoreArr, toDateIndex);
                const delta = countryDeltaForDimension[country.id];
                if (!isNil(scoreToday) && !isNil(delta)) {
                    maxScore = Math.max(maxScore, scoreToday || 0);
                    minScore = Math.min(minScore, scoreToday || 0);
                    const percent = (() => {
                        if (delta === 0) return 0;
                        if (delta < 0) return delta / minDelta;
                        if (delta > 0) return delta / maxDelta;
                    })();
                    res.push({
                        score_today: scoreToday,
                        delta,
                        geo: country,
                        score_size: null,
                        delta_color: {
                            type:
                                delta > 0 ? 'up' : delta < 0 ? 'down' : 'same',
                            percent,
                        },
                    });
                }
            } catch (err) {
            }
        });
        res.forEach(circleData => {
            circleData.score_size =
                maxScore === minScore
                    ? 0
                    : Number(
                          (circleData.score_today - minScore) /
                              (maxScore - minScore)
                      );
        });
        return res;
    }

    public static extrapulateDeltaFromScoresRange(
        scores: { [countryId: number]: Ifs.IAPIScores },
        startDate: moment.Moment,
        endDate: moment.Moment
    ): IDeltaByCountry {
        const deltas: IDeltaByCountry = {};
        _.forOwn(scores, (countryScores, countryId) => {
            const rangeStartScore = scoreDatesHandler.get(
                countryScores,
                startDate.format(DATE_FORMAT)
            );
            const rangeEndScore = scoreDatesHandler.get(
                countryScores,
                endDate.format(DATE_FORMAT)
            );
            deltas[Number(countryId)] = rangeEndScore - rangeStartScore;
        });
        return deltas;
    }

    public static extrapulateDeltaFromScoresRangeContingent(
        scores: { [countryId: number]: Ifs.IAPIScores },
        startDate: moment.Moment,
        endDate: moment.Moment
    ): IDeltaByCountry {
        const deltas: IDeltaByCountry = {};
        _.forOwn(scores, (countryScores, countryId) => {
            const rangeStartScore = scoreDatesHandler.getContingent(
                countryScores,
                startDate.format(DATE_FORMAT)
            );
            const rangeEndScore = scoreDatesHandler.getContingent(
                countryScores,
                endDate.format(DATE_FORMAT)
            );
            deltas[Number(countryId)] = rangeEndScore - rangeStartScore;
        });
        return deltas;
    }

    public static calcScoreDimensionChart(
        scores: { [countryID: string]: { [dateString: string]: number } },
        countries: Ifs.IActiveCountryMap,
    ) {
        const res: Array<{
            date: string;
            [country: string]: any;
        }> = [];

        const mapped: any = {};
        _.forEach(countries, (country: Ifs.IActiveCountry) => {
            const dateToScore = scores[country.id];
            _.forEach(dateToScore, (score: number, dateFormat: string) => {
                if (!mapped[dateFormat]) mapped[dateFormat] = {};
                mapped[dateFormat][country.name] = score;
            });
        });

        _.forEach(_.keys(mapped), dateString => {
            const cur = {
                date: dateString,
            };
            Object.assign(cur, mapped[dateString]);
            res.push(cur);
        });
        return res;
    }

    public static getDimensionsTreeNode(
        dimensionsTree: Ifs.IActiveDimensionTree,
        dimensionID: number
    ): Ifs.IActiveDimensionTree {
        if (dimensionsTree.id === dimensionID) return dimensionsTree;
        if (!dimensionsTree.children) return null;
        for (const children of dimensionsTree.children) {
            const isMatched = this.getDimensionsTreeNode(children, dimensionID);
            if (isMatched) return isMatched;
        }
        return null;
    }
    public static flattenDimensionsNodeToIds(
        dimensionsTree: Ifs.IActiveDimensionTree
    ): number[] {
        let res: number[] = [dimensionsTree.id];
        if (dimensionsTree.children) {
            for (const children of dimensionsTree.children) {
                res = res.concat(this.flattenDimensionsNodeToIds(children));
            }
        }
        return res;
    }
    public static getUscoresForDimensionsTree(
        uscores: Ifs.IAPIUscore,
        dimensionsTree: Ifs.IActiveDimensionTree,
        dimensionID: number,
        date: string,
        country: number
    ) {
        const dimensionIds = this.flattenDimensionsNodeToIds(dimensionsTree);
        let max: number = null;
        for (const dID of dimensionIds) {
            if (!uscores[date]) {
                continue;
            }
            if (!uscores[date][dID]) {
                continue;
            }
            if (!uscores[date][dID][country]) {
                continue;
            }
            const optionalUscores = uscores[date][dID][country];
            if (Array.isArray(optionalUscores) && optionalUscores.length > 0) {
                for (const uscore of optionalUscores) {
                    const abs = Math.abs(uscore.impact);
                    if (abs === 3) return abs;
                    if (max === null || max < abs) {
                        max = abs;
                    }
                }
            }
        }
        return max;
    }

    public static singleDateFactory(key: string): moment.Moment {
        if (undefined === this.momentCache[key]) {
            this.momentCache[key] = moment(key, DATE_FORMAT);
        }

        return this.momentCache[key];
    }

    private static momentCache: { [dateKey: string]: moment.Moment } = {};
}
