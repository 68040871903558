import React from 'react';
import { inject, observer } from 'mobx-react';
import PastScoreList from './GQPastScoreList';
import GQButton from '../../GQButton/GQButton';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';
import GQMenu from '../../GQMenu/GQMenu';
import GQMenuItem from '../../GQMenu/GQMenuItem';
import GQModal, { E_MODAL_ALIGN } from '../../GQModal/GQModal';
import GQGenericPopup from '../../GQModal/GQGenericPopup';
import { IEventUScore } from '../../../interfaces';
import { IMobxRootState } from '../../../RootStore';
import { EventScoreType } from 'components/eventsFeed/EventsFeedInterfaces';
const ALL_SCORES_ACTIONS_MENU_NAME = 'score-event-all-scores-actions';

export interface IGQPastScoreContainerProps {
    pastScores: IEventUScore[];
    canUserDelete: boolean;
    canUserEdit: boolean;
    canSeeMachineScores?: boolean;
    onEdit: (score: IEventUScore) => void;
    onDelete: (score: IEventUScore) => void;
    onDeleteAllScores: (scoreType: EventScoreType) => void;
    editMode: boolean;
    newScore?: IEventUScore;
}

export interface IGQPastScoreContainerState {
    showAllScoresActionsMenu: boolean;
    showApproveDeleteAllModal: boolean;
    humanScores: IEventUScore[];
    machineScores: IEventUScore[];
    currentScoreType?: EventScoreType;
}

@inject(({ UserStore }: IMobxRootState) => {
    return {
        canSeeMachineScores:
            UserStore.geoquantFlagsPermission.can_see_archived_uscores,
    };
})
@observer
export default class PastScoreContainer extends React.Component<
    IGQPastScoreContainerProps,
    IGQPastScoreContainerState
> {
    public loadAddScoreAnimation: boolean;

    constructor(props: IGQPastScoreContainerProps) {
        super(props);
        this.state = {
            showAllScoresActionsMenu: false,
            showApproveDeleteAllModal: false,
            humanScores: props.pastScores.filter(
                (score: IEventUScore) => score.human
            ),
            machineScores: props.pastScores.filter(
                (score: IEventUScore) => !score.human
            ),
        };
    }

    public componentWillUnmount() {
        this.ensureAllScoresActionsMenuClosed();
    }

    public UNSAFE_componentWillReceiveProps(
        nextProps: IGQPastScoreContainerProps
    ) {
        this.loadAddScoreAnimation = false;
        if (nextProps.newScore !== this.props.newScore) {
            this.loadAddScoreAnimation = true;
        }

        const haveNewPastScores: boolean =
            JSON.stringify(this.props.pastScores) !==
            JSON.stringify(nextProps.pastScores);

        if (this.state.showAllScoresActionsMenu && haveNewPastScores) {
            this.ensureAllScoresActionsMenuClosed();
        }

        if (haveNewPastScores) {
            this.setState({
                humanScores: nextProps.pastScores.filter(
                    (score: IEventUScore) => score.human
                ),
                machineScores: nextProps.pastScores.filter(
                    (score: IEventUScore) => !score.human
                ),
            });
        }
    }

    public render() {
        const { canSeeMachineScores } = this.props;
        const { humanScores, machineScores } = this.state;

        return (
            <div className="past-score-container">
                <PastScoreList
                    canUserDelete={this.props.canUserDelete}
                    canUserEdit={this.props.canUserEdit}
                    title="Human Scores"
                    isMenuOpen={
                        this.state.showAllScoresActionsMenu &&
                        this.state.currentScoreType === 'human'
                    }
                    scores={humanScores}
                    editMode={this.props.editMode}
                    onDelete={this.props.onDelete}
                    onEdit={this.props.onEdit}
                    toggleShowAllScoresMenu={buttonRef =>
                        this.toggleShowAllScoresMenu(buttonRef, 'human')
                    }
                />

                {canSeeMachineScores && (
                    <PastScoreList
                        canUserDelete={this.props.canUserDelete}
                        canUserEdit={this.props.canUserEdit}
                        title="Machine Scores"
                        isMenuOpen={
                            this.state.showAllScoresActionsMenu &&
                            this.state.currentScoreType === 'machine'
                        }
                        scores={machineScores}
                        editMode={this.props.editMode}
                        onDelete={this.props.onDelete}
                        onEdit={this.props.onEdit}
                        toggleShowAllScoresMenu={buttonRef =>
                            this.toggleShowAllScoresMenu(buttonRef, 'machine')
                        }
                    />
                )}

                <GQModal
                    align={E_MODAL_ALIGN.MIDDLE}
                    show={this.state.showApproveDeleteAllModal}>
                    <GQGenericPopup
                        title=""
                        text={`Delete all ${this.state.currentScoreType} scores?`}
                        icon="gqi-alert"
                        onClose={this.toggleDeleteApproveModal}
                        buttons={[
                            <GQButton
                                key="popup-confirm"
                                caption="Yes, delete them all"
                                onClick={this.deleteAllScores}
                            />,
                            <GQButton
                                key="popup-cancel"
                                caption="Cancel"
                                onClick={this.toggleDeleteApproveModal}
                            />,
                        ]}
                    />
                </GQModal>
            </div>
        );
    }

    private toggleShowAllScoresMenu = (
        currentButtonRef: any = null,
        currentScoreType: any = null
    ) => {
        if (GQPopupStorage.isExisting(ALL_SCORES_ACTIONS_MENU_NAME)) {
            GQPopupStorage.addData(ALL_SCORES_ACTIONS_MENU_NAME, null);
        } else if (currentButtonRef) {
            this.setState({
                currentScoreType,
            });
            GQPopupStorage.addData(ALL_SCORES_ACTIONS_MENU_NAME, {
                element: currentButtonRef,
                orientation: 'bottom left',
                tooltipData: (
                    <GQMenu>
                        <GQMenuItem
                            label="Delete All"
                            icon="gqi-delete"
                            onClick={this.toggleDeleteApproveModal}
                        />
                    </GQMenu>
                ),
            });
        }
        this.setState({
            showAllScoresActionsMenu: !this.state.showAllScoresActionsMenu,
        });
    };

    private ensureAllScoresActionsMenuClosed = () => {
        if (this.state.showAllScoresActionsMenu) {
            this.toggleShowAllScoresMenu();
        }
    };

    private toggleDeleteApproveModal = () => {
        this.setState(
            prevState => ({
                showApproveDeleteAllModal: !prevState.showApproveDeleteAllModal,
            }),
            this.ensureAllScoresActionsMenuClosed
        );
    };

    private deleteAllScores = () => {
        this.toggleDeleteApproveModal();
        if (this.state.currentScoreType) {
            this.props.onDeleteAllScores(this.state.currentScoreType);
        }
    };
}
